import React from 'react'
import tiktok_landing from '@/assets/tiktok_landing.png'
import cuate from '@/assets/cuate.png'
import {
  MainContainer,
  InnerContainer,
  RightCol,
  LeftCol,
  StyledImage,
  StyledMainHeading,
  StyledIconWrapper,
  HeadingsWrapper,
  StyledSubHeading,
  StyledSpan
} from './styles'

const HeroSection = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <LeftCol>
          <StyledIconWrapper>
            <StyledImage src={tiktok_landing} alt='Ticktok Image'></StyledImage>
          </StyledIconWrapper>
          <HeadingsWrapper>
            <StyledMainHeading>
              {`EASIEST WAY TO START YOUR TIKTOK SHOP`}
              <StyledSpan>{` DROP SHIPPING`}</StyledSpan>
            </StyledMainHeading>
            <StyledSubHeading>{`Boost your sales with our curatedbest-selling products with AI`}</StyledSubHeading>
          </HeadingsWrapper>
        </LeftCol>
        <RightCol>
          <StyledImage src={cuate} alt='Ticktok Image'></StyledImage>
        </RightCol>
      </InnerContainer>
    </MainContainer>
  )
}

export default HeroSection
