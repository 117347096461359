import styled from "styled-components";
import AntButton from "uiKits/Button";
import AntTable from "uiKits/Table";

export const MainContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: 45px;
`;

export const MainHeading = styled.h2`
  margin: 0px;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const MainSubHeading = styled.h2`
  margin: 0px;
  color: #6b7280;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.6px;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 80px;
`;

export const TableWrapper = styled.div`
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const StyledTable = styled(AntTable)`
  .ant-table-thead tr th {
    text-align: center;
  }
  .ant-table-tbody tr td {
    text-align: center;
  }
`;

export const StyledButton = styled(AntButton)`
  font-size: 18px;
  height: 50px;
  padding: 4px 40px;
`;
