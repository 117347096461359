import React from "react";
import {
  MainContainer,
  IconWrapper,
  InnerContainer,
  ValueWrapper,
  LabelWrapper,
} from "./styles";

const MetaDataCard = ({ value, label, icon }) => {
  return (
    <MainContainer>
      <InnerContainer>
        <IconWrapper>{icon}</IconWrapper>
        <ValueWrapper>{value}</ValueWrapper>
        <LabelWrapper>{label}</LabelWrapper>
      </InnerContainer>
    </MainContainer>
  );
};

export default MetaDataCard;
