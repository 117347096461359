import React from "react";
import { Form } from "antd";
import InputWithlabel from "shared/InputWithLabel";
import {
  StyledHeading,
  StyledSubHeading,
  MainLayout,
  StyledCheckbox,
  CheckboxWrapper,
  FlexContainer,
  StyledButton,
  ButtonWrapper,
  StyledSelect,
  StyledLabel,
} from "./styles";

const SecondStep = ({ Next, setStepsPayload, stepsPayload }) => {
  const [form] = Form.useForm();
  const plainOptions = [
    {
      label: "Affiliate Marketing",
      value: "Affiliate Marketing",
    },
    {
      label: "Paid Ads",
      value: "Paid Ads",
    },
    {
      label: "Live Selling",
      value: "Live Selling",
    },
    {
      label: "Online Marketplace(ex: Amazon)",
      value: "Online Marketplace(ex: Amazon)",
    },
    {
      label: "Brick & Mortar and offline Traffic",
      value: "Brick & Mortar and offline Traffic",
    },
    {
      label: "other",
      value: "other",
    },
  ];

  const selectOptions = [
    {
      value: "<$1k",
      label: "<$1k",
    },
    {
      value: "$1k - $4k",
      label: "$1k - $4k",
    },
    {
      value: "$4k - $40k",
      label: "$4k - $40k",
    },
    {
      value: "$40k+",
      label: "$40k+",
    },
  ];

  const onCheckboxChange = (checkedValues) => {
    setStepsPayload({
      ...stepsPayload,
      businessInfo: {
        ...stepsPayload.businessInfo,
        businessSources: checkedValues,
      },
    });
  };

  const handleFinish = (values) => {
    setStepsPayload({
      ...stepsPayload,
      businessInfo: {
        ...stepsPayload.businessInfo,
        website: values.website,
        businessRevenue: values.businessRevenue,
      },
    });
    Next();
  };
  return (
    <MainLayout>
      <StyledHeading>Now Let's Get to Know Your Business</StyledHeading>
      <StyledSubHeading>
        This helps our onboarding team in providing you with personalized
        product and service.
      </StyledSubHeading>
      <CheckboxWrapper>
        <StyledCheckbox options={plainOptions} onChange={onCheckboxChange} />
      </CheckboxWrapper>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <FlexContainer>
          <InputWithlabel
            label="Do you have any website?"
            name="website"
            placeholder="Enter your Website Address"
            width="19.4rem"
          />
          <Form.Item
            label={<StyledLabel>"What's your monthly revenue?"</StyledLabel>}
            name="businessRevenue"
            rules={[]}
          >
            <StyledSelect placeholder="Please Select" options={selectOptions} />
          </Form.Item>
        </FlexContainer>
        <ButtonWrapper>
          <StyledButton htmlType="submit">Continue</StyledButton>
        </ButtonWrapper>
      </Form>
    </MainLayout>
  );
};

export default SecondStep;
