import React from "react";

const UserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21.6503 19.8751C20.2225 17.4067 18.0222 15.6367 15.4544 14.7976C16.7246 14.0415 17.7114 12.8893 18.2634 11.518C18.8153 10.1468 18.9019 8.63224 18.5098 7.20701C18.1177 5.78178 17.2685 4.52467 16.0928 3.62873C14.9171 2.73279 13.4798 2.24756 12.0016 2.24756C10.5234 2.24756 9.08609 2.73279 7.91037 3.62873C6.73465 4.52467 5.88553 5.78178 5.49342 7.20701C5.1013 8.63224 5.18787 10.1468 5.73983 11.518C6.2918 12.8893 7.27863 14.0415 8.54878 14.7976C5.98097 15.6357 3.78066 17.4057 2.35285 19.8751C2.30049 19.9605 2.26576 20.0555 2.2507 20.1545C2.23565 20.2535 2.24059 20.3545 2.26521 20.4516C2.28984 20.5487 2.33366 20.6399 2.39409 20.7197C2.45452 20.7996 2.53033 20.8666 2.61706 20.9167C2.70378 20.9667 2.79966 20.999 2.89904 21.0114C2.99842 21.0238 3.09928 21.0162 3.19568 20.9891C3.29208 20.9619 3.38205 20.9157 3.4603 20.8532C3.53855 20.7907 3.60349 20.7131 3.65128 20.6251C5.41753 17.5726 8.53941 15.7501 12.0016 15.7501C15.4638 15.7501 18.5857 17.5726 20.3519 20.6251C20.3997 20.7131 20.4646 20.7907 20.5429 20.8532C20.6211 20.9157 20.7111 20.9619 20.8075 20.9891C20.9039 21.0162 21.0048 21.0238 21.1042 21.0114C21.2035 20.999 21.2994 20.9667 21.3861 20.9167C21.4729 20.8666 21.5487 20.7996 21.6091 20.7197C21.6695 20.6399 21.7134 20.5487 21.738 20.4516C21.7626 20.3545 21.7675 20.2535 21.7525 20.1545C21.7374 20.0555 21.7027 19.9605 21.6503 19.8751ZM6.7516 9.00011C6.7516 7.96176 7.0595 6.94672 7.63638 6.08337C8.21326 5.22001 9.0332 4.5471 9.99251 4.14974C10.9518 3.75238 12.0074 3.64841 13.0258 3.85099C14.0442 4.05356 14.9797 4.55357 15.7139 5.2878C16.4481 6.02202 16.9481 6.95748 17.1507 7.97588C17.3533 8.99429 17.2493 10.0499 16.852 11.0092C16.4546 11.9685 15.7817 12.7884 14.9183 13.3653C14.055 13.9422 13.0399 14.2501 12.0016 14.2501C10.6097 14.2486 9.27517 13.695 8.29093 12.7108C7.30669 11.7265 6.75309 10.392 6.7516 9.00011Z"
      fill={props.color}
    />
  </svg>
);

export default UserIcon;
