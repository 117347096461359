import React from "react";

const BillingInformationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M8.25 15H14.25M2.25 9.75H21.75M17.25 15H18.75M3.75 5.25C3 5.25 2.25 6 2.25 6.75V17.25C2.25 18 3 18.75 3.75 18.75H20.25C21 18.75 21.75 18 21.75 17.25V6.75C21.75 6 21 5.25 20.25 5.25H3.75Z"
      stroke={props.selected ? "#ED6928" : "#6B7280"}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </svg>
);

export default BillingInformationIcon;
