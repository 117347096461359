import React from "react";
import { Divider, message } from "antd";
import { useSubscriptionMutation } from "api/pricingApi";
import {
  CardsWrapper,
  ProCardWrapper,
  InnerContainer,
  MainContainer,
  StyledHeading,
  StyledSubHeading,
  InnerWrapper,
  StyledPrice,
  StyledCategory,
  StyledButton,
  StyledButtonEnterprise,
  StyledSubHeadingEnterprise,
  ButtonWrapper,
  StyledFeaturedHeading,
  StyledUl,
  StyledLi,
} from "./styles";
// import { PayPalButtons } from "@paypal/react-paypal-js";

const PriceCards = () => {
  // const [isPayPalVisible, setPayPalVisible] = useState(false); // Manage PayPal button visibility
  // const handleSubscribeClick = () => {
  //   setPayPalVisible(true); // Show PayPal buttons on click
  // };
  const [Subscribe, { isLoading }] = useSubscriptionMutation();

  const handleSubscription = async (plan) => {
    const response = await Subscribe(plan);
    console.log("response", response);
    if (response?.error) {
      message.error(response?.error?.data?.message);
    } else if (response?.data) {
      // Check if the response contains a link
      const link = response.data.link;
      // Redirect the user to the subscription link
      window.location.href = link; // Opens the link in the current tab
      // Or use window.open(link, '_blank'); // Opens the link in a new tab
    }
  };
  return (
    <MainContainer>
      <InnerContainer>
        <CardsWrapper>
          <StyledHeading>{`Starter`}</StyledHeading>
          <StyledSubHeading>{`Everything you need to get started with finding new winning products`}</StyledSubHeading>
          <Divider />
          <InnerWrapper>
            <StyledPrice>{`$49.99/`}</StyledPrice>
            <StyledCategory>{` Monthly`}</StyledCategory>
            <ButtonWrapper>
              <StyledButton
                onClick={() =>
                  handleSubscription({ type: "BASIC", plan: "MONTHLY" })
                }
              >
                {`TRY FOR FREE`}
              </StyledButton>
            </ButtonWrapper>
            <StyledSubHeading>{`14-Day Free Trial. Cancel Anytime.`}</StyledSubHeading>
          </InnerWrapper>
          <StyledFeaturedHeading>{`What’s included`}</StyledFeaturedHeading>
          <StyledUl>
            <StyledLi>{`14-days free trial`}</StyledLi>
            <StyledLi>{`Automated Order Updates`}</StyledLi>
            <StyledLi>{`Automated Order Fulfillment (within 3-business days)`}</StyledLi>
            <StyledLi>{`Auto-Checkout`}</StyledLi>
            <StyledLi>{`24/7 Chat Support`}</StyledLi>
          </StyledUl>

          {/* {isPayPalVisible && (
            <PayPalButtons
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: process.env.REACT_APP_PAYPAL_MONTHLY_BASIC_PLAN, // Use REACT_APP prefix for env variables
                  // Add vaulting option here if supported
                  vault: true,
                });
              }}
              onApprove={(data, actions) => {
                console.log("Subscription successful", data);
                // Handle subscription success, e.g., redirect to confirmation
              }}
              onError={(err) => {
                console.error("Subscription error:", err);
              }}
              style={{
                layout: "vertical",
                color: "gold",
                shape: "rect",
                label: "subscribe",
              }}
            />
          )} */}
        </CardsWrapper>

        {/* Pro and Enterprise Cards - unchanged */}
        <ProCardWrapper>
          <StyledHeading>{`Pro`}</StyledHeading>
          <StyledSubHeading>{`The most popular plan if you are serious about getting started`}</StyledSubHeading>
          <Divider />
          <InnerWrapper>
            <StyledPrice>{`$49.99/`}</StyledPrice>
            <StyledCategory>{` Monthly`}</StyledCategory>
            <ButtonWrapper>
              <StyledButton>{`TRY FOR FREE`}</StyledButton>
            </ButtonWrapper>
            <StyledSubHeading>{`14-Day Free Trial. Cancel Anytime.`}</StyledSubHeading>
          </InnerWrapper>
          <StyledFeaturedHeading>{`What’s included`}</StyledFeaturedHeading>
          <StyledUl>
            <StyledLi>{`14-days free trial`}</StyledLi>
            <StyledLi>{`Everything in Starter, PLUS:`}</StyledLi>
            <StyledLi>{`Private Source Request`}</StyledLi>
            <StyledLi>{`TikTok Shop Weekly Trending Report`}</StyledLi>
            <StyledLi>{`Appeal Management Service`}</StyledLi>
            <StyledLi>{`24/7 1:1 Account Manager Support`}</StyledLi>
          </StyledUl>
        </ProCardWrapper>

        <CardsWrapper>
          <StyledHeading>{`Enterprise`}</StyledHeading>
          <StyledSubHeading>{`The biggest plan to maximize your potential of success`}</StyledSubHeading>
          <Divider />
          <InnerWrapper>
            <StyledPrice>{`Custom`}</StyledPrice>
            <ButtonWrapper>
              <StyledButtonEnterprise>{`Contact Sales`}</StyledButtonEnterprise>
            </ButtonWrapper>
            <StyledSubHeadingEnterprise>{`14-Day Free Trial. Cancel Anytime.`}</StyledSubHeadingEnterprise>
          </InnerWrapper>
          <StyledFeaturedHeading>{`What’s included`}</StyledFeaturedHeading>
          <StyledUl>
            <StyledLi>{`Everything in Pro, PLUS:`}</StyledLi>
            <StyledLi>{`Multiple Shop management`}</StyledLi>
            <StyledLi>{`Master Account management`}</StyledLi>
            <StyledLi>{`1:1 TTS Expert Support`}</StyledLi>
            <StyledLi>{`Branded Products`}</StyledLi>
          </StyledUl>
        </CardsWrapper>
      </InnerContainer>
    </MainContainer>
  );
};

export default PriceCards;
