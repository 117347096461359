import { Table } from "antd";
import moment from "moment";

export const columns = [
  // {
  //   title: "Product",
  //   key: "product",
  //   render: (record) => <img src={record.productImg} alt="Product img" />,
  //   width: "10%",
  // },
  {
    title: "Product Name",
    key: "product_name",
    dataIndex: "name",
    width: "20%",
  },
  {
    title: "Created At",
    key: "created_at",
    render: ({ createdAt }) => moment(createdAt).format("MM/DD/YYYY"),
    width: "15%",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "stock_status",
    width: "15%",
  },
  {
    title: "SKU",
    key: "sku",
    dataIndex: "sku",
    width: "15%",
  },
  {
    title: "Price",
    key: "price",
    render: ({ price }) => `$${price}`,
    width: "15%",
  },
  {
    title: "Badge",
    key: "badge",
    dataIndex: "badge",
    width: "20%",
  },
  Table.EXPAND_COLUMN,
];
