import styled from "styled-components";
import AntButton from "uiKits/Button";

export const MainContainer = styled.div`
  .ant-input-outlined:hover {
    border-color: #ed6928;
  }
  .ant-input-outlined:focus-within {
    border-color: #ed6928;
  }
  #TextArea {
    height: 200px;
  }
  .ant-form-item {
    margin-bottom: 50px !important;
  }
  @media (max-width: 768px) {
    .ant-form-item {
      margin-bottom: 20px !important;
    }
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  gap: 7%;
  align-items: center;
  justify-content: start;
  .ant-form-item {
    width: 46%;
  }
  .ant-input,
  .ant-input-number-input {
    line-height: 2.571429;
  }
  .ant-input-outlined:hover {
    border-color: #ed6928;
  }
  .ant-input-outlined:focus {
    border-color: #ed6928;
  }
  .ant-input-number-outlined:hover {
    border-color: #ed6928;
  }
  .ant-input-number-outlined:focus {
    border-color: #ed6928;
  }
  .ant-input-number-outlined:focus-within {
    border-color: #ed6928;
  }
  .ant-select:hover .ant-select-selector {
    border-color: #ed6928 !important;
  }
  .ant-select-single {
    height: 44px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #ed6928 !important;
    background-color: #ed6928 !important;
  }
  .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #ed6928;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .ant-form-item {
      width: 100%;
    }
  }
`;

export const MainSubHeading = styled.h3`
  margin: 0px;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 165% */
  letter-spacing: -0.04px;
  text-transform: capitalize;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 23px;
  }
`;

export const StyledButton = styled(AntButton)`
  color: #fafafa;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.04px;
  text-transform: capitalize;
  height: 48px;
  width: 200px;
  &:hover {
    background: #fff !important;
  }
`;
