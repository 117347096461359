import React, { useState } from "react";
import { Form, Input, Row, Col } from "antd";
import {
  Header,
  Section,
  SectionHeader,
  PlanContainer,
  PlanDetails,
  PlanName,
  PlanPrice,
  PlanIncludes,
  PaymentContainer,
  CardDetails,
  CardInfo,
  CardType,
  CardExpiry,
  EditButton,
  MainContainer,
  InnerContainer,
  PlanDes,
  Price,
  PricePeriod,
  PlanIncludesDes,
  PlanIncludesWrapper,
  ButtonWrapper,
  StyledButton,
  PriceWrapper,
  SaveButton,
  FormContainer,
  StyledInput,
  EditWrapper,
  DetailsWrapper,
  CardsDetails,
  CardWrapper,
  CardEnding,
} from "./styles";
import CheckIcon from "@/assets/icons/checkIcon";
import cache from "utils/cache";

const SubscriptionBilling = () => {
  const [isEditing, setIsEditing] = useState(false);
  const user = cache.getItem("user");
  console.log("user", user);

  const onFinish = (values) => {
    console.log("Received values: ", values);
    // Handle form submission
    setIsEditing(false);
  };

  return (
    <MainContainer>
      <InnerContainer>
        <Header>Subscription And Billing</Header>

        <Section>
          <ButtonWrapper>
            <SectionHeader>Current Plan</SectionHeader>
            <StyledButton>Change Payment Plan</StyledButton>
          </ButtonWrapper>
          <PlanContainer>
            <PlanDetails>
              <PlanName>{user?.subscription?.planType}</PlanName>
              <PlanDes>
                We will use your account details for billing according to plan
                change
              </PlanDes>
              <PriceWrapper>
                <div>
                  <PlanPrice>Monthly Plan /</PlanPrice> <Price>$49.99 </Price>
                  <PricePeriod>/ monthly</PricePeriod>
                </div>
                <CheckIcon />
              </PriceWrapper>
              <PlanIncludesWrapper>
                <PlanIncludes>Includes: </PlanIncludes>
                <PlanIncludesDes>
                  Rights Registrations & Metadata Delivery, Unlimited Users,
                  100,000 Songs, Free ISRCs, Unlimited Recordings, Unlimited
                  Releases, 1000 GB Storage, Unlimited Documents & Contacts,
                  100% Direct Royalty Payouts, PurchaseChat & E-mail Support
                </PlanIncludesDes>
              </PlanIncludesWrapper>
            </PlanDetails>
          </PlanContainer>
        </Section>

        {/* <PaymentContainer>
          <SectionHeader>Payment Methods</SectionHeader>
          <p>Update your billing details and address</p>
          <CardDetails>
            <DetailsWrapper>
              <CardsDetails>Card Details</CardsDetails>
              <p>Select Payment Method</p>
            </DetailsWrapper>
            <CardInfo>
              <CardWrapper>
                <CardType>Visa</CardType>
                <div>
                  <CardEnding CardEnding>Visa ending in 5678</CardEnding>
                  <CardExpiry>Expiry 06/2024</CardExpiry>
                  <EditWrapper>
                    <p>Set as Default</p>
                    <EditButton onClick={() => setIsEditing(true)}>
                      Edit
                    </EditButton>
                  </EditWrapper>
                </div>
              </CardWrapper>
            </CardInfo>
          </CardDetails>
        </PaymentContainer> */}

        {isEditing && (
          <FormContainer>
            <SectionHeader>Edit Card Details</SectionHeader>
            <Form layout="vertical" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="cardNumber"
                    label="Card Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your card number",
                      },
                    ]}
                  >
                    <StyledInput />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="expiryMonth"
                    label="Expiry Date"
                    rules={[
                      { required: true, message: "Please enter expiry month" },
                    ]}
                  >
                    <StyledInput placeholder="MM" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="expiryYear"
                    label=" "
                    rules={[
                      { required: true, message: "Please enter expiry year" },
                    ]}
                  >
                    <StyledInput placeholder="YY" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="zipCode"
                    label="Zip Code"
                    rules={[
                      { required: true, message: "Please enter your zip code" },
                    ]}
                  >
                    <StyledInput />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="cvc"
                    label="CVC"
                    rules={[
                      { required: true, message: "Please enter your CVC" },
                    ]}
                  >
                    <StyledInput />
                  </Form.Item>
                </Col>
              </Row>
              <StyledButton type="submit">Save Changes</StyledButton>
            </Form>
          </FormContainer>
        )}
      </InnerContainer>
    </MainContainer>
  );
};

export default SubscriptionBilling;
