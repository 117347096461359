import { Button, Modal, Divider } from "antd";
import styled from "styled-components";
import AntInput from "uiKits/Input";

export const MainLayout = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftLayout = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightLayout = styled.div`
  width: 40%;
  padding: 2.25rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledModal = styled(Modal)`
  width: 56.3125rem !important;
  .ant-modal-content {
    height: 39.5rem;
    padding: unset;
  }
`;

export const Heading = styled.h2`
  color: #6b7280;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
`;

export const SubHeading = styled.h5`
  color: #6b7280;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 121.44%; /* 1.21438rem */
  text-align: center;
`;

export const StyledButton = styled(Button)`
  border-radius: 0.25rem;
  border: 1px solid #9ca3af;
`;

export const LoginButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledDivider = styled(Divider)`
  color: #6b7280 !important;
  font-family: Inter;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  padding: 0 3.5rem;
  &:before {
    border: 2px solid #6b7280;
    border-block-start-color: unset !important;
  }
  &:after {
    border: 2px solid #6b7280;
    border-block-start-color: unset !important;
  }
`;

export const EmailText = styled.p`
  color: #6b7280;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
  margin: 0;
  margin-bottom: 0.9rem;
`;

export const StyledInput = styled(AntInput)`
  height: 3.5rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.38rem;
`;

export const StyledContinueButton = styled(Button)`
  width: 11.875rem;
  height: 2.5rem;
  border-radius: 0.3125rem;
  background: #ed6928;
  color: var(--White-background, var(--Color-Neutral-white, #fff));
  text-align: center;
  font-family: Inter;
  font-size: 0.8535rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.81rem;
  &:hover {
    color: #ed6928 !important;
    background: #ffffff !important;
    border-color: #ed6928 !important;
  }
`;

export const TermAndConditionsText = styled.p`
  color: #6b7280;
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.06263rem;
  margin: 0;
  margin-top: 1.44rem;
`;
