import React from "react";
import NA1 from "@/assets/NA1.png";
import NA2 from "@/assets/NA2.png";
import NA3 from "@/assets/NA3.png";
import NA4 from "@/assets/NA4.png";
import {
  InnerContainer,
  MainContainer,
  StyledHeading,
  Col1,
  Col2,
  Col3,
  Col4,
  StyledImg,
  ColWrapper,
  TextCard,
  StyledTitle,
  StyledSubTitle,
  StyledButton,
  StyledImageWrapper,
} from "./styles";

const NewArrivalSection = () => {
  return (
    <MainContainer>
      <StyledHeading>{`New Arrival`}</StyledHeading>
      <InnerContainer>
        <Col1>
          <StyledImg src={NA1} />
          <TextCard>
            <StyledTitle>{`PlayStation 5`}</StyledTitle>
            <StyledSubTitle>{`Black and White version of the PS5 coming out on sale.`}</StyledSubTitle>
            <StyledButton>{`Shop Now`}</StyledButton>
          </TextCard>
        </Col1>
        <Col2>
          <StyledImageWrapper>
            <StyledImg src={NA2} />
            <TextCard>
              <StyledTitle>{`Women’s Collections`}</StyledTitle>
              <StyledSubTitle>{`Featured woman collections that give you another vibe.`}</StyledSubTitle>
              <StyledButton>{`Shop Now`}</StyledButton>
            </TextCard>
          </StyledImageWrapper>
          <ColWrapper>
            <Col3>
              <StyledImg src={NA3} />
              <TextCard>
                <StyledTitle>{`Speakers`}</StyledTitle>
                <StyledSubTitle>{`Amazon wireless speakers`}</StyledSubTitle>
                <StyledButton>{`Shop Now`}</StyledButton>
              </TextCard>
            </Col3>
            <Col4>
              <StyledImg src={NA4} />
              <TextCard>
                <StyledTitle>{`Perfume`}</StyledTitle>
                <StyledSubTitle>{`GUCCI INTENSE OUD EDP`}</StyledSubTitle>
                <StyledButton>{`Shop Now`}</StyledButton>
              </TextCard>
            </Col4>
          </ColWrapper>
        </Col2>
      </InnerContainer>
    </MainContainer>
  );
};

export default NewArrivalSection;
