import AnnouncementBar from "@/components/MarketingPage/AnnouncementBar";
import Footer from "@/components/MarketingPage/Footer";
import Header from "@/components/MarketingPage/Header";
import React from "react";

const Layout = ({ children }) => {
  return (
    <>
      <AnnouncementBar />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
