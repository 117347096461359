import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, message } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import cache from "utils/cache";
import { useLoginMutation, useSignUpMutation } from "api/authApi"; // Added signUp mutation
import SignInImage from "@/assets/Images/signIn.png";
import {
  MainLayout,
  LeftLayout,
  StyledModal,
  RightLayout,
  Heading,
  SubHeading,
  StyledButton,
  LoginButtonWrapper,
  StyledDivider,
  EmailText,
  StyledInput,
  InputWrapper,
  StyledContinueButton,
  TermAndConditionsText,
} from "./styles";
import Loader from "shared/Loader";

const SignInModal = ({ isModalOpen, toggleModal, isSignUpMode }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [signUp, { isLoading: isSignUpLoading }] = useSignUpMutation();
  const isLoading = isLoginLoading || isSignUpLoading;

  const handleContinue = async () => {
    // Check for required fields
    if (!email || !password) {
      message.error("Please fill in all required fields.");
      return;
    }

    if (isSignUpMode) {
      // Sign-up logic
      const res = await signUp({ email, password });
      if (res.data) {
        message.success("Sign up successful! Please log in.");
        toggleModal();
      } else if (res.error) {
        message.error(res?.error?.data?.message?.message);
      }
    } else {
      // Login logic
      const res = await login({ email, password });
      if (res.data) {
        cache.setItem("user", res?.data?.user);
        navigate("/landing_page");
        toggleModal();
      } else if (res.error) {
        message.error(res?.error?.data?.message?.message);
      }
    }
  };

  return (
    <StyledModal
      open={isModalOpen}
      onCancel={toggleModal}
      footer={false}
      centered
    >
      <MainLayout>
        <LeftLayout>
          <Image
            style={{
              height: "39.5rem",
              borderBottomLeftRadius: "8px",
              borderTopLeftRadius: "8px",
            }}
            preview={false}
            alt="Image"
            src={SignInImage}
          />
        </LeftLayout>
        <RightLayout>
          <Heading>
            {isSignUpMode ? "Create an Account" : "Welcome Back"}
          </Heading>
          <SubHeading>
            {isSignUpMode
              ? "Join us for immediate access to the best quality on-trend products."
              : "Immediate access to best quality on-trend products, sourced from trusted suppliers."}
          </SubHeading>
          <LoginButtonWrapper>
            <StyledButton
              size="large"
              icon={<GoogleOutlined />}
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_BASE_URL}auth/google`)
              }
            >
              Login with Google
            </StyledButton>
          </LoginButtonWrapper>
          <StyledDivider plain>Or</StyledDivider>
          <EmailText>Email</EmailText>
          <InputWrapper>
            <StyledInput
              placeholder="User Name"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
            <StyledInput
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </InputWrapper>
          <LoginButtonWrapper>
            <StyledContinueButton size="large" onClick={handleContinue}>
              {isSignUpMode ? "Sign Up" : "Login"}
            </StyledContinueButton>
          </LoginButtonWrapper>
          <TermAndConditionsText>
            By clicking continue, you agree to our Terms and Conditions and
            Privacy policy.
          </TermAndConditionsText>
        </RightLayout>
      </MainLayout>
      <Loader isLoading={isLoading} />
    </StyledModal>
  );
};

export default SignInModal;
