import styled from "styled-components";
import tickIcon from "@/assets/tickIcon.png";

export const MainContainer = styled.div``;

export const InnerContainer = styled.div`
  display: flex;
  gap: 2%;
  justify-content: center;
  align-items: baseline;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardsWrapper = styled.div`
  width: 30%;
  border-radius: 8px;
  border: 1px solid #dae0e7;
  background: #fafafa;
  padding: 20px;
  @media screen and (max-width: 768px) {
    width: 87%;
    margin-top: 30px;
  }
`;

export const ProCardWrapper = styled.div`
  width: 30%;
  border-radius: 8px;
  border: 1px solid #dae0e7;
  background: #fafafa;
  padding: 20px;
  border-top: 25px solid #ed6928;
  border-bottom: 4px solid #ed6928;
  @media screen and (max-width: 768px) {
    width: 87%;
    margin-top: 30px;
  }
`;

export const StyledHeading = styled.h3`
  margin: 0;
  color: #171725;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const StyledSubHeading = styled.p`
  margin: 0;
  color: #585858;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 20px;
`;

export const StyledSubHeadingEnterprise = styled.p`
  margin: 0;
  color: #585858;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 20px;
  visibility: hidden;
`;

export const InnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const StyledButton = styled.a`
  color: #fafafb;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 5px;
  border: 0px solid #94e3d0;
  background: #ed6928;
  padding: 10px 40px;
  cursor: pointer;
`;

export const StyledButtonEnterprise = styled.a`
  color: #fafafb;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 5px;
  border: 0px solid #94e3d0;
  background: #000;
  padding: 10px 40px;
  cursor: pointer;
`;

export const StyledPrice = styled.span`
  color: #171725;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
`;

export const StyledCategory = styled.span`
  color: #171725;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
`;

export const StyledFeaturedHeading = styled.span`
  color: #ed6928;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const StyledUl = styled.ul`
  padding-left: 0px;
`;

export const StyledLi = styled.li`
  color: var(--black, #334155);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  background: url(${tickIcon}) no-repeat left center;
  list-style: none;
  padding-left: 20px;
  padding-bottom: 7px;
  padding-top: 7px;
`;
