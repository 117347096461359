import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "auth";

const PrivateRoutes = () => {
  const isAuthenticated = AuthService.isAuthenticated();
  console.log("isAuthenticated: ", isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  const user = AuthService.getUser();
  if (user && user.firstTimeLogin) {
    return <Navigate to="/sign-up" />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
