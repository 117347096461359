import { Button } from "antd";
import styled from "styled-components";
import AntCheckbox from "uiKits/Checkbox";

export const MainContainer = styled.div`
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 85%;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 4.25rem;
  padding-top: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0.87rem;
  }
`;

export const StyledCheckbox = styled(AntCheckbox)`
  margin-top: 2.75rem;

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 34.1875rem;
  color: #ffffff;
  display: block;
  border-radius: 0.3125rem;
  height: 3rem;
  margin: 0 auto;
  background: #ed6928;
  &:hover {
    background: #ed6928 !important;
    color: #ffffff !important;
    border: 2px solid #ed6928 !important;
  }

  @media screen and (max-width: 768px) {
    width: 20.0625rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 4.44rem;

  @media screen and (max-width: 768px) {
    margin-top: 2.31rem;
  }
`;

export const Heading = styled.h4`
  color: #000;
  font-family: Inter;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-top: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const SubHeading = styled.p`
  margin: 0;
  margin-top: 0.75rem;
  color: #585858;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
