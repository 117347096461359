import React from "react";
import { InnerContainer, MainContainer, StyledHeading } from "./styles";

const AnnouncementBar = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <StyledHeading>{`📌 Join our community space to get the latest secret source for your success`}</StyledHeading>
      </InnerContainer>
    </MainContainer>
  );
};

export default AnnouncementBar;
