import styled from 'styled-components'
import Group26 from '@/assets/Group26.png'

export const MainContainer = styled.div`
  width: 100%;
  background-color: #fff;
`

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const RightCol = styled.div`
  padding-right: 150px;
  padding-top: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
    padding-top: 0px;
    img {
      width: 100%;
    }
  }
`

export const LeftCol = styled.div`
  width: 48%;
  display: flex;
  gap: 14px;
  align-items: start;
  padding-left: 45px;
  padding-top: 42px;
  @media screen and (max-width: 768px) {
    width: 90%;
    gap: 0px;
    margin: 0 auto;
    padding-left: 0px;
    padding-top: 40px;
    text-align: center;
  }
`

export const StyledIconWrapper = styled.div`
  @media screen and (max-width: 768px) {
    margin-right: -10px;
    img {
      width: 38px;
    }
  }
`

export const StyledImage = styled.img``

export const HeadingsWrapper = styled.div`
  background-image: url(${Group26});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 20% 50%;
  height: 342px;
  @media screen and (max-width: 768px) {
    height: 320px;
    margin-right: -10px;
  }
`

export const StyledMainHeading = styled.h2`
  margin: 0px;
  color: #010002;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 57.6px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`

export const StyledSubHeading = styled.p`
  margin: 0px;
  color: #525252;
  padding-top: 26px;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const StyledSpan = styled.span`
  color: #ed6928;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`
