import styled from "styled-components";
import AntBreadCrumbs from "uiKits/BreadCrums";
import AntButton from "uiKits/Button";

export const StyledBreadCrumbs = styled(AntBreadCrumbs)``;

export const MainContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: 35px;
`;

export const InnerContainer = styled.div`
  margin-top: 50px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
`;

export const StyledHeading = styled.h2`
  margin: 0px;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CartFooter = styled.div`
  margin-top: 20px;
`;

export const EstimatedTimeWithIcon = styled.div`
  display: flex;
  gap: 10px;
`;

export const TimeText = styled.p`
  margin: 0px;
  color: var(--Text2, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.48px;
  width: 30%;
`;

export const TotalWrapper = styled.div`
  width: 65%;
  text-align: right;
`;

export const StyledTotal = styled.h2`
  margin: 0px;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: right;
`;

export const StyledButton = styled(AntButton)`
  font-size: 18px;
  height: 50px;
  padding: 4px 40px;
  border-radius: 4px;
`;

export const EmptyCartWrapper = styled.div`
  margin-top: 180px;
  margin-bottom: 180px;
  text-align: center;
`;

export const EmptyCartImage = styled.img``;

export const EmptyCartText = styled.p`
  margin-top: 80px;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
