import styled from "styled-components";

export const MainContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  margin-top: 70px;
  margin-bottom: 70px;
`;

export const InnerContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    gap: 50px;
  }
`;

export const StyledIconWithText = styled.div`
  text-align: center;
`;

export const StyledIcon = styled.img``;

export const StyledHeading = styled.h3`
  margin: 0px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 8px;
  padding-top: 24px;
`;

export const StyledSubHeading = styled.p`
  margin: 0px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;
