import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "auth";

const PublicRoutes = () => {
  const isAuthenticated = AuthService.isAuthenticated();

  return !isAuthenticated ? <Outlet /> : <Navigate to="/landing_page" />;
};

export default PublicRoutes;
