import React from "react";
import { Form } from "antd";
import InputWithLabel from "shared/InputWithLabel";
import {
  FlexContainer,
  BodyContainer,
  ButtonWrapper,
  Heading,
  SubHeading,
  MainContainer,
  StyledButton,
  StyledCheckbox,
} from "./styles";

const FirstStep = ({ Next, setStepsPayload, stepsPayload }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setStepsPayload({ ...stepsPayload, profileInfo: values });
    Next();
  };

  return (
    <MainContainer>
      <Heading>Log In to Your Account</Heading>
      <SubHeading>Enter your Details</SubHeading>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <BodyContainer>
          <FlexContainer>
            <InputWithLabel
              label="First Name"
              name="firstName"
              placeholder="Enter your First Name"
              rules={[{ required: true, message: "First name is required" }]}
            />
            <InputWithLabel
              label="Last Name"
              name="lastName"
              placeholder="Enter your Last Name"
              rules={[{ required: true, message: "Last name is required" }]}
            />
          </FlexContainer>
          <FlexContainer>
            <InputWithLabel
              label="Create Password"
              type="password"
              name="password"
              placeholder="Enter your Password"
              rules={[{ required: true, message: "Password is required" }]}
            />
            <InputWithLabel
              label="Phone Number"
              name="phoneNumber"
              placeholder="+1(123)-456-890"
              rules={[{ required: true, message: "Phone number is required" }]}
            />
          </FlexContainer>
          <FlexContainer>
            <InputWithLabel
              label="Referral Code"
              name="referralCode"
              placeholder="Enter your Referral Code (Optional)"
              rules={[]}
            />
          </FlexContainer>
        </BodyContainer>
        <Form.Item name="updates" valuePropName="checked" rules={[]}>
          <StyledCheckbox>
            Receive order updates, deals & tips via SMS from EcomDropship
          </StyledCheckbox>
        </Form.Item>
        <ButtonWrapper>
          <StyledButton type="primary" htmlType="submit" size="large">
            Continue
          </StyledButton>
        </ButtonWrapper>
      </Form>
    </MainContainer>
  );
};

export default FirstStep;
