import React from "react";
import AnnouncementBar from "@/components/MarketingPage/AnnouncementBar";
import Header from "@/components/MarketingPage/Header";
import Pricing from "./Pricing";

const PricingPage = () => {
  return (
    <>
      <AnnouncementBar />
      <Header />
      <Pricing />
    </>
  );
};

export default PricingPage;
