import React from "react";
import { HeroSection, StyledBreadCrumbs, StyledImage } from "./styles";
import heroBanner from "@/assets/heroBanner.png";
import Layout from "layout";
import ProductSection from "../BestSellingProductListPage/ProductSection";
import IconWithTextSection from "../BestSellingProductListPage/IconWithTextSection";
import Loader from "shared/Loader";
import { useGetProductsQuery } from "api/productApi";

const items = [
  {
    title: <a href="">Product</a>,
  },
  {
    title: <a href="">Third Party Products</a>,
  },
];

const ThirdPartyProductsPage = () => {
  const { data: thirdPartyData, isLoading } =
    useGetProductsQuery("THIRD_PARTY");

  return (
    <Layout>
      <StyledBreadCrumbs items={items} />
      <HeroSection>
        <StyledImage src={heroBanner} />
      </HeroSection>
      <ProductSection
        heading="Third Party Products"
        products={thirdPartyData?.products}
      />
      <IconWithTextSection />
      <Loader isLoading={isLoading} />
    </Layout>
  );
};

export default ThirdPartyProductsPage;
