export const truncateWithEllipsis = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

// insert key in data to make it unique for expandable

export const insertKey = (data) => {
  const manipulatedData = data?.map((item, index) => {
    return { ...item, key: index };
  });

  return manipulatedData;
};
