import React, { useState } from "react";
import ImageSection from "@/assets/imageSection.png";
import textWithImagemb from "@/assets/textWithImagemb.png";
import {
  MainContainer,
  InnerContainer,
  StyledRow,
  RightCol,
  LeftCol,
  TextCard,
  StyledImage,
  StyledMainHeading,
  StyledHeading,
  StyledText,
  SimpleTextCard,
  HeadingsWrapper,
  StyledSubHeading,
  StyledSpan,
  StyledSpacer,
  StyledRowmb,
  ImageWrapper,
} from "./styles";

const ImageWithTextSection = () => {
  const [clickedCard, setClickedCard] = useState("text");

  const handleCardClick = (card) => {
    setClickedCard(card === clickedCard ? null : card);
  };
  return (
    <MainContainer>
      <InnerContainer>
        <HeadingsWrapper>
          <StyledMainHeading>
            {`WHY`}
            <StyledSpan>{` ECOMDROPSHIP?`}</StyledSpan>
          </StyledMainHeading>
        </HeadingsWrapper>
        <StyledRow>
          <LeftCol>
            <TextCard
              className={clickedCard === "text" ? "highlighted" : ""}
              onClick={() => handleCardClick("text")}
            >
              <StyledSubHeading>{`Weekly Curated`}</StyledSubHeading>
              <StyledHeading>{`Best Selling Products are here!`}</StyledHeading>
              <StyledText>{`Provide curated best-selling products for your business with AI! Skyrocket your sales with ECOMDROPSHIP.`}</StyledText>
            </TextCard>
            <StyledSpacer></StyledSpacer>
            <SimpleTextCard
              className={clickedCard === "simpleText" ? "highlighted" : ""}
              onClick={() => handleCardClick("simpleText")}
            >
              <StyledSubHeading>{`Selling`}</StyledSubHeading>
              <StyledHeading>{`Making TikTok Selling Simple!`}</StyledHeading>
              <StyledText>
                {
                  "Source winning products through ECOM-DROPSHIP and upload to your? TikTok Shop with One-Click product import."
                }
              </StyledText>
              <StyledText>{`You focus on your brand! Let ECOMDROPSHIP do the rest!`}</StyledText>
            </SimpleTextCard>
          </LeftCol>
          <RightCol>
            <StyledImage
              src={
                clickedCard === "text"
                  ? `${ImageSection}`
                  : `${textWithImagemb}`
              }
              alt="Ticktok Image"
            />
          </RightCol>
        </StyledRow>

        <StyledRowmb>
          <LeftCol>
            <TextCard
              className={clickedCard === "text" ? "highlighted" : ""}
              onClick={() => handleCardClick("text")}
            >
              <StyledSubHeading>{`Weekly Curated`}</StyledSubHeading>
              <StyledHeading>{`Best Selling Products are here!`}</StyledHeading>
              <StyledText>{`Provide curated best-selling products for your business with AI! Skyrocket your sales with ECOMDROPSHIP.`}</StyledText>
            </TextCard>
            <ImageWrapper>
              <StyledImage src={ImageSection} alt="Ticktok Image" />
            </ImageWrapper>
            <SimpleTextCard
              className={clickedCard === "simpleText" ? "highlighted" : ""}
              onClick={() => handleCardClick("simpleText")}
            >
              <StyledSubHeading>{`Selling`}</StyledSubHeading>
              <StyledHeading>{`Making TikTok Selling Simple!`}</StyledHeading>
              <StyledText>
                {
                  "Source winning products through ECOM-DROPSHIP and upload to your? TikTok Shop with One-Click product import."
                }
              </StyledText>
              <StyledText>{`You focus on your brand! Let ECOMDROPSHIP do the rest!`}</StyledText>
            </SimpleTextCard>
            <ImageWrapper>
              <StyledImage src={textWithImagemb} alt="Ticktok Image" />
            </ImageWrapper>
          </LeftCol>
        </StyledRowmb>
      </InnerContainer>
    </MainContainer>
  );
};

export default ImageWithTextSection;
