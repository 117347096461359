import styled from "styled-components";
import AntSteps from "uiKits/Steps";
import background from "@/assets/Images/signUp.png";

export const BackgroundImage = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // height: 100vh;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;
`;

export const InnerContainer = styled.div`
  margin: 4.06rem 0rem;
  width: 60%;
  background: #ffffff;
  padding: 3.56rem 9.12rem;

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    padding: 3.56rem 2.12rem;
  }
`;

export const StyledSteps = styled(AntSteps)`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 50%;
    display: flex;
    justify-content: center;
  }
`;

export const StepsTitle = styled.p`
  margin: 0;
  color: #b5b5b5;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;
