import { Button, Checkbox } from "antd";
import styled from "styled-components";
import AntSelect from "uiKits/Select";

export const MainLayout = styled.div`
  margin: 0 auto;
  margin-top: 3.62rem;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 85%;
  }
`;

export const StyledHeading = styled.h4`
  margin: 0;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    text-align: start;
  }
`;

export const StyledSubHeading = styled.p`
  color: #585858;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
    text-align: start;
  }
`;

export const StyledCheckbox = styled(Checkbox.Group)`
  display: inline-grid;
  gap: 15px;
  .ant-checkbox-inner {
    &:hover {
      border-color: #ed6928 !important;
    }
    &:focus-visible {
      border-color: #ed6928 !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ed6928 !important;
    border-color: #ed6928 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    &:after {
      border-color: #ffffff !important;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 1.3rem;
  flex-direction: column;
  margin-top: 2.3rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 1.12rem;
  margin-top: 2.94rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 7.75rem;

  @media screen and (max-width: 768px) {
    margin-top: 2.31rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 34.1875rem;
  color: #ffffff;
  display: block;
  border-radius: 0.3125rem;
  height: 3rem;
  margin: 0 auto;
  background: #ed6928;
  &:hover {
    background: #ed6928 !important;
    color: #ffffff !important;
    border: 2px solid #ed6928 !important;
  }

  @media screen and (max-width: 768px) {
    width: 20.0625rem;
  }
`;

export const StyledSelect = styled(AntSelect)`
  width: 19.4rem !important;
  height: 3.5rem;
`;

export const StyledLabel = styled.label`
  color: var(--black-fonts-headings, #2d2e2e);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
