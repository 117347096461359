import React from "react";
import {
  BalanceWrapper,
  ButtonWrapper,
  ItemPrice,
  ItemTitle,
  ItemWrapper,
  MainContainer,
  MainHeading,
  StyledButton,
  StyledDivider,
  TotalItemTitle,
} from "./styles";
import { useNavigate } from "react-router-dom";
import cache from "utils/cache";

const OrderSummary = () => {
  const navigate = useNavigate();
  const cachedItems = cache.getItem("cartItems") || [];
  console.log("cachedItems", cachedItems);

  const handleNavigate = () => {
    navigate("/order_payment", {
      state: { totalAmount: calculateTotalWithTax() },
    });
  };

  // Calculate total price of all items
  const calculateTotalPrice = () => {
    return cachedItems
      .reduce((total, item) => {
        const itemPrice =
          item.variantPrice !== 0 ? item.variantPrice : item.productPrice;
        return total + itemPrice * item.productQuantity;
      }, 0)
      .toFixed(2); // Ensure the total is always formatted to 2 decimal places
  };

  // Calculate 4% tax based on total price
  const calculateTax = () => {
    const totalPrice = parseFloat(calculateTotalPrice());
    return (totalPrice * 0.04).toFixed(2); // 4% tax
  };

  // Calculate the final total including tax
  const calculateTotalWithTax = () => {
    const totalPrice = parseFloat(calculateTotalPrice());
    const tax = parseFloat(calculateTax());
    return (totalPrice + tax).toFixed(2); // Total including tax
  };

  return (
    <MainContainer>
      <MainHeading>{`Order Summary`}</MainHeading>
      <BalanceWrapper>
        <ItemWrapper>
          <ItemTitle>{`Items (${cachedItems?.length})`}</ItemTitle>
          <ItemPrice>{`$${calculateTotalPrice()}`}</ItemPrice>
        </ItemWrapper>
        <ItemWrapper>
          <ItemTitle>{`Tax Collected:`}</ItemTitle>
          <ItemPrice>{`$${calculateTax()}`}</ItemPrice>
        </ItemWrapper>
      </BalanceWrapper>
      <StyledDivider />
      <ItemWrapper>
        <TotalItemTitle>{`Order Total:`}</TotalItemTitle>
        <ItemPrice>{`$${calculateTotalWithTax()}`}</ItemPrice>
      </ItemWrapper>
      <ButtonWrapper>
        <StyledButton onClick={handleNavigate}>Proceed to payment</StyledButton>
      </ButtonWrapper>
    </MainContainer>
  );
};

export default OrderSummary;
