import styled from "styled-components";
import AntInput from "uiKits/Input";
import { Form } from "antd";
import AntButton from "uiKits/Button";

export const StyledInput = styled(AntInput)`
  background: #fff;
  height: 42px;
`;

export const StyledButton = styled(AntButton)`
  margin-right: 8px;
  height: 50px;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const CancelButton = styled(AntButton)`
  width: 30%;
  height: 50px;
  color: #000;
  background: #fff;
  border-color: #c8c8c8;
  &:hover {
    background: #ed6928 !important;
    color: #fff !important;
  }
`;

export const SaveButton = styled(AntButton)`
  width: 65%;
  height: 50px;
`;

export const MainHeading = styled.h3`
  margin: 0px;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
