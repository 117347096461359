import { InputNumber } from "antd";
import styled from "styled-components";
import AntSelect from "uiKits/Select";

export const MainContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 35px;
`;

export const InnerContainer = styled.div``;

export const ProductInfoWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

export const HeadingCostWrapper = styled.div`
  padding-top: 12px;
  width: 20%;
`;

export const StyledImage = styled.img`
  width: 141px;
  height: 114.459px;
`;

export const ProductHeading = styled.h4`
  margin: 0px;
  color: var(--Text2, #000);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

export const ProductCost = styled.h5`
  margin: 0px;
  color: #9ca3af;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.48px;
`;

export const StyledQuantity = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: flex-end;
  gap: 25%;
  .ant-select-focused .ant-select-selector {
    border-color: #ed6928 !important;
  }
`;

export const StyledNumberInput = styled(InputNumber)``;
