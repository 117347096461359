import React from "react";
import DeleteIcon from "@/assets/icons/deleteIcon";
import {
  ContentWrapper,
  HeadingWrapper,
  MainLayout,
  NotesWrapper,
  PriceHeadingWrapper,
  PriceWrapper,
  StyledButton,
} from "./styles";
import EditIcon from "@/assets/icons/editIcon";

const ExpandedRowTableContent = ({ description, msrp }) => {
  return (
    <MainLayout>
      <NotesWrapper>
        <HeadingWrapper>{`Description`}</HeadingWrapper>
        <ContentWrapper>{description}</ContentWrapper>
      </NotesWrapper>
      <NotesWrapper>
        <PriceHeadingWrapper>{`MSRP`}</PriceHeadingWrapper>
        <PriceWrapper>{msrp}</PriceWrapper>
      </NotesWrapper>
      <div style={{ cursor: "pointer" }}>
        <DeleteIcon />
      </div>
    </MainLayout>
  );
};

export default ExpandedRowTableContent;
