import React from "react";
import styled from "styled-components";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Flex } from "antd";
import cache from "utils/cache";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateOrderMutation } from "api/orderApi";

const OrderPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [createOrder] = useCreateOrderMutation();
  const cachedItems = cache.getItem("cartItems") || [];
  const { totalAmount } = location.state || {};
  console.log("totalAmount", totalAmount);
  console.log("cachedItems", cachedItems);

  const MainHeading = styled.h3`
    margin: 20px 0px 20px 0px;
    color: var(--Text2, #000);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `;

  const handleApprove = async (data, actions) => {
    try {
      // Capture the funds from the transaction
      const order = await actions.order.capture();
      console.log("Payment successfully captured:", order);

      // Transform the cachedItems to the desired format
      const formattedItems = cachedItems.map((item) => ({
        productId: item.productId,
        variantId: item.variantId,
        quantity: item.productQuantity,
        price: item.variantPrice !== 0 ? item.variantPrice : item.productPrice,
      }));

      // Create the payload with formatted items and total amount
      const payload = {
        items: formattedItems,
        amount: 20, // totalAmount passed from location.state
        orderId: order.id, // PayPal order ID
        payment: {
          grossAmount: 24000,
          shippingFee: 300,
          tax: "10%",
          status: order.purchase_units[0].payments.captures[0].status,
          invoiceId: order.purchase_units[0].payments.captures[0].id,
        },
      };
      const response = await createOrder(payload);

      if (response?.data) {
        navigate("/order_success_page");
        cache.setItem("cartItems", []);
      }

      // if (!response.ok) {
      //   throw new Error("Failed to create order on backend");
      // }

      // const data = await response.json();
      // console.log("Order successfully created on backend:", data);
    } catch (error) {
      console.error("Error capturing payment:", error);
    }
  };

  return (
    <Flex
      justify="center"
      align="center"
      vertical
      style={{
        height: "100vh",
        textAlign: "center",
        width: "100%",
      }}
    >
      <MainHeading>{`Enter Payment Details`}</MainHeading>
      <div style={{ width: "500px" }}>
        <PayPalButtons
          style={{
            layout: "vertical",
            color: "gold",
            shape: "rect",
            label: "paypal",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: totalAmount,
                  },
                },
              ],
            });
          }}
          onApprove={handleApprove}
          onError={(err) => console.error("PayPal Checkout Error:", err)}
        />
      </div>
    </Flex>
  );
};

export default OrderPayment;
