import styled from "styled-components";

export const MainContainer = styled.div`
  background: #2a2a2a;
  width: 100%;
`;

export const InnerContainer = styled.div`
  text-align: center;
  padding: 12px;
`;

export const StyledHeading = styled.h4`
  margin: 0;
  color: #fff;
  font-family: Inter;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
