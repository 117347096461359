import React from "react";
import { Form } from "antd";
import { MainContainer, StyledInput, StyledLabel } from "./styles";

const InputWithLabel = ({
  label,
  name,
  placeholder,
  width,
  rules,
  type = "text",
}) => {
  return (
    <MainContainer>
      <Form.Item
        label={<StyledLabel>{label}</StyledLabel>}
        name={name}
        rules={rules}
        style={{ width: width ? width : "25.6rem" }}
      >
        <StyledInput size="large" placeholder={placeholder} type={type} />
      </Form.Item>
    </MainContainer>
  );
};

export default InputWithLabel;
