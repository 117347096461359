import React from "react";

const Lock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.18997 0.799805C7.23519 0.799805 6.31951 1.17909 5.64438 1.85422C4.96925 2.52935 4.58997 3.44503 4.58997 4.3998V7.1998H4.18997C3.76562 7.1998 3.35865 7.36838 3.05859 7.66843C2.75854 7.96849 2.58997 8.37546 2.58997 8.7998V13.5998C2.58997 14.0242 2.75854 14.4311 3.05859 14.7312C3.35865 15.0312 3.76562 15.1998 4.18997 15.1998H12.19C12.6143 15.1998 13.0213 15.0312 13.3213 14.7312C13.6214 14.4311 13.79 14.0242 13.79 13.5998V8.7998C13.79 8.37546 13.6214 7.96849 13.3213 7.66843C13.0213 7.36838 12.6143 7.1998 12.19 7.1998H11.79V4.3998C11.79 3.44503 11.4107 2.52935 10.7355 1.85422C10.0604 1.17909 9.14475 0.799805 8.18997 0.799805ZM10.59 7.1998V4.3998C10.59 3.76329 10.3371 3.15284 9.88702 2.70275C9.43694 2.25266 8.82649 1.9998 8.18997 1.9998C7.55345 1.9998 6.943 2.25266 6.49291 2.70275C6.04282 3.15284 5.78997 3.76329 5.78997 4.3998V7.1998H10.59Z"
      fill="#9CA3AF"
    />
  </svg>
);

export default Lock;
