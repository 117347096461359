import styled from "styled-components";
import { Carousel } from "antd";

export const MainContainer = styled.div`
  width: 100%;
  background-color: #fbfbfb;
  margin-top: 80px;
  margin-bottom: 50px;
`;

export const InnerContainer = styled.div``;

export const VideosWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const VideosWrappermb = styled.div`
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 20px;
  }
`;

export const VideoWrapper = styled.div`
  text-align: center;
`;

export const StyledImage = styled.img`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledImageWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const HeadingsWrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const StyledMainHeading = styled.h2`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const VideoHeading = styled.h3`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding-top: 28px;
`;

export const VideoSubHeading = styled.p`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-top: 16px;
`;
export const StyledSpan = styled.span`
  margin: 0;
  color: #ed6928;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 14px;
    height: 14px;
    background: #b5b5b5;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    background: #ed6928;
  }
  .slick-dots-bottom {
    bottom: -20px;
  }
`;
