import React from "react";
import {
  BannerSlide,
  BannerWrapper,
  ButtonWrapper,
  MainContainer,
  MainHeading,
  MainHeadingSpan,
  SlideInnerContent,
  StyledButton,
  StyledCarousel,
  StyledHeading,
  StyledIcon,
} from "./styles";
import Slide1 from "@/assets/Slide1.png";
import IphoneIcon from "@/assets/IphoneIcon.png";
import RightArrowIcon from "@/assets/icons/rightArrowIcon";
import cache from "utils/cache";

const HeroBannerCarousel = () => {
  const user = cache.getItem("user");
  console.log("user: ", user);
  return (
    <MainContainer>
      <MainHeading>{`Welcome Back, `}</MainHeading>
      <MainHeadingSpan>{user?.firstName}</MainHeadingSpan>
      <StyledCarousel autoplay={true}>
        <BannerSlide>
          <BannerWrapper src={Slide1}></BannerWrapper>
          <SlideInnerContent>
            <StyledIcon src={IphoneIcon} />
            <StyledHeading>
              {`Up to 10% `}
              <br />
              {`off Voucher`}
            </StyledHeading>
            <ButtonWrapper>
              <StyledButton href="#">{`Shop Now`}</StyledButton>
              <RightArrowIcon />
            </ButtonWrapper>
          </SlideInnerContent>
        </BannerSlide>
        <BannerSlide>
          <BannerWrapper src={Slide1}></BannerWrapper>
          <SlideInnerContent>
            <StyledIcon src={IphoneIcon} />
            <StyledHeading>
              {`Up to 10% `}
              <br />
              {`off Voucher`}
            </StyledHeading>
            <ButtonWrapper>
              <StyledButton href="#">{`Shop Now`}</StyledButton>
              <RightArrowIcon />
            </ButtonWrapper>
          </SlideInnerContent>
        </BannerSlide>
        <BannerSlide>
          <BannerWrapper src={Slide1}></BannerWrapper>
          <SlideInnerContent>
            <StyledIcon src={IphoneIcon} />
            <StyledHeading>
              {`Up to 10% `}
              <br />
              {`off Voucher`}
            </StyledHeading>
            <ButtonWrapper>
              <StyledButton href="#">{`Shop Now`}</StyledButton>
              <RightArrowIcon />
            </ButtonWrapper>
          </SlideInnerContent>
        </BannerSlide>
      </StyledCarousel>
    </MainContainer>
  );
};

export default HeroBannerCarousel;
