import React from "react";
import {
  InnerContainer,
  LeftCol,
  MainContainer,
  MainHeading,
  RightCol,
} from "./styles";
import AddressPaymentDetails from "../AddressPaymentDetails";
import OrderSummary from "../OrderSummary";

const Checkout = () => {
  return (
    <MainContainer>
      <MainHeading>{`Checkout`}</MainHeading>
      <InnerContainer>
        <LeftCol>
          <AddressPaymentDetails />
        </LeftCol>
        <RightCol>
          <OrderSummary />
        </RightCol>
      </InnerContainer>
    </MainContainer>
  );
};

export default Checkout;
