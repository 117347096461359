import React from "react";
import { Pagination } from "antd";
import ProductCard from "../ProductCard";
import {
  MainContainer,
  StyledHeading,
  InnerContainer,
  PaginationWrapper,
} from "./styles";

const onChange = (pageNumber) => {};

const ProductSection = ({ heading, products }) => {
  return (
    <MainContainer>
      <StyledHeading>{heading}</StyledHeading>
      <InnerContainer>
        {products?.map((product) => (
          <ProductCard product={product} />
        ))}
      </InnerContainer>
      <PaginationWrapper>
        <Pagination defaultCurrent={1} total={500} onChange={onChange} />
      </PaginationWrapper>
    </MainContainer>
  );
};

export default ProductSection;
