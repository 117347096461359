import styled from "styled-components";

export const MainContainer = styled.div``;

export const InnerContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    width: 85%;
    margin-top: 40px;
  }
`;

export const BannerWrapper = styled.div``;

export const MainBanner = styled.img`
  width: 100%;
`;

export const MainHeading = styled.h2`
  margin: 0px;
  color: var(--04, #010002);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const MainSubHeading = styled.h3`
  margin: 0px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.6px; /* 88% */
  letter-spacing: -0.04px;
  text-transform: capitalize;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
