import styled from "styled-components";

export const MainContainer = styled.div`
  width: 8rem;
  height: 11.375rem;
  border-radius: 0.25rem;
  background: #ed6928;
`;

export const InnerContainer = styled.div`
  padding: 1.37rem 0.38rem 1.37rem 0.38rem;
  display: flex;
  flex-direction: column;
  gap: 1.63rem;
`;

export const IconWrapper = styled.div`
  width: 30%;
  margin: 0 auto;
`;

export const ValueWrapper = styled.h4`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

export const LabelWrapper = styled.h4`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;
