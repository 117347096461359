import React, { useState } from "react";
import { Form, Row, Col, message } from "antd";
import {
  StyledInput,
  StyledFormItem,
  ButtonWrapper,
  CancelButton,
  SaveButton,
  MainHeading,
} from "./styles";
import { useAddShippingAddressMutation } from "api/authApi";

const AddressForm = () => {
  const [form] = Form.useForm();
  const [addShippiingAddress, { isLoading }] = useAddShippingAddressMutation();

  const onFinish = async (values) => {
    const response = await addShippiingAddress(values);
    message.success(response?.data?.message);
  };

  return (
    <Form form={form} name="addressForm" onFinish={onFinish} layout="vertical">
      <StyledFormItem>
        <MainHeading>{`Add New Address`}</MainHeading>
      </StyledFormItem>
      <Row gutter={16}>
        <Col span={12}>
          <StyledFormItem
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem
            name="lastName"
            label="Last Name"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StyledFormItem
            name="streetAddress"
            label="Street Address"
            rules={[
              { required: true, message: "Please input your street address!" },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <StyledFormItem
            name="apartmentNumber"
            label="Apartment Number"
            rules={[
              {
                required: true,
                message: "Please input your apartment number!",
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>
        <Col span={8}>
          <StyledFormItem
            name="state"
            label="State"
            rules={[{ required: true, message: "Please input your state!" }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>
        <Col span={8}>
          <StyledFormItem
            name="zip"
            label="Zip"
            rules={[{ required: true, message: "Please input your zip!" }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>
      </Row>
      <Form.Item>
        <ButtonWrapper>
          <CancelButton htmlType="button" onClick={() => form.resetFields()}>
            Cancel
          </CancelButton>
          <SaveButton htmlType="submit">Save this Address</SaveButton>
        </ButtonWrapper>
      </Form.Item>
    </Form>
  );
};

export default AddressForm;
