import styled from "styled-components";
import { Button } from "antd";
import { Table } from "antd";

export const MainLayout = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const NotesWrapper = styled.div`
  width: 30%;
`;

export const HeadingWrapper = styled.h4`
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

export const PriceHeadingWrapper = styled.h4`
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin: 0;
`;

export const ContentWrapper = styled.p`
  color: #6b7280;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding-top: 20px;
`;

export const PriceWrapper = styled.p`
  color: #6b7280;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding-top: 20px;
  text-align: center;
`;

export const StyledTable = styled(Table)``;
export const StyledButton = styled(Button)``;
