import styled from "styled-components";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Dropdown, Image, Divider } from "antd";

export const MainContainer = styled.div`
  background-color: #e5e7eb29;
  width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  margin: 0 auto;
  padding: 20px 0px;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div``;

export const StyledLogo = styled.img`
  width: 120px;
  cursor: pointer;
`;

export const MenuWrapperDesktop = styled.div`
  width: 30%;
  // display: block;
  // .ant-menu {
  //   justify-content: space-evenly;
  //   background: none !important;
  // }
  // .ant-menu-horizontal {
  //   border-bottom: none !important;
  // }
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuWrapperMobile = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? "0" : "-250px")};
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: -3px 0 6px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 99;
`;

export const StyledBarsWrapper = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const SyledBarsmb = styled(MenuOutlined)`
  font-size: 30px;
  color: #585858;
`;

export const StyledClosemb = styled(CloseOutlined)`
  padding: 20px 18px;
  color: #585858;
`;

export const SearchWrapper = styled.div`
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const LoginButton = styled.a`
  color: #ed6928;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
`;

export const SignupButton = styled.a`
  display: flex;
  padding: 6px 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background: #ed6928;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  color: #000;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.975rem;
  text-decoration: none;
  &:hover {
    color: #ed6928;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  .ant-dropdown {
    inset: 136.562px auto auto 47.293px !important;
  }
`;

export const ProductContainer = styled.div`
  width: 40.9375rem;
  height: 15rem;
  border-radius: 0.3125rem;
  border: 1px solid #b1b1b1;
  background: var(--Color-Neutral-white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 2.06rem 0 0 2.63rem;
  margin-top: 30px;
`;

export const ImageTextWrapper = styled.div`
  cursor: pointer;
`;

export const StyledImage = styled(Image)``;

export const StyledText = styled.h4`
  color: #000;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 150% */
  &:hover {
    color: #ed6928;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 5px;
`;

export const StyledDivider = styled(Divider)`
  margin: 0px;
`;

export const StyledMenuLink = styled.a`
  padding-left: 10px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  text-transform: capitalize;
`;

export const StyledMenuContainer = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  width: 180px;
  padding: 10px;
`;

export const ImageUserName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 16px;
  padding-bottom: 20px;
`;

export const MenuImage = styled.img`
  width: 65px;
`;

export const MenuText = styled.span`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
  text-transform: capitalize;
`;

export const MenuUserName = styled.span`
  margin: 0px;
  color: #ed6928;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: capitalize;
`;

export const MenuEmail = styled.p`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  text-transform: lowercase;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
