import React from "react";
import DeliveryIcon from "@/assets/DeliveryIcon.png";
import SupportIcon from "@/assets/SupportIcon.png";
import SecurityIcon from "@/assets/SecurityIcon.png";
import {
  InnerContainer,
  MainContainer,
  StyledHeading,
  StyledIcon,
  StyledIconWithText,
  StyledSubHeading,
} from "./styles";

const IconWithTextSection = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <StyledIconWithText>
          <StyledIcon src={DeliveryIcon} />
          <StyledHeading>{`FREE AND FAST DELIVERY`}</StyledHeading>
          <StyledSubHeading>{`Free delivery for all orders over $140`}</StyledSubHeading>
        </StyledIconWithText>
        <StyledIconWithText>
          <StyledIcon src={SupportIcon} />
          <StyledHeading>{`24/7 CUSTOMER SERVICE`}</StyledHeading>
          <StyledSubHeading>{`Friendly 24/7 customer support`}</StyledSubHeading>
        </StyledIconWithText>
        <StyledIconWithText>
          <StyledIcon src={SecurityIcon} />
          <StyledHeading>{`MONEY BACK GUARANTEE`}</StyledHeading>
          <StyledSubHeading>{`We return money within 30 days`}</StyledSubHeading>
        </StyledIconWithText>
      </InnerContainer>
    </MainContainer>
  );
};

export default IconWithTextSection;
