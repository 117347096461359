import styled from "styled-components";
import { Menu } from "antd";

export const MainContainer = styled.div`
  margin: 0 auto;
  margin-top: 22px;
  width: 85%;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  gap: 3%;
  @media (max-width: 768px) {
    gap: 0%;
    flex-direction: column;
  }
`;

export const LeftCol = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const RightCol = styled.div`
  width: 100%;
`;

export const StyledMenu = styled(Menu)`
  border-inline-end: unset !important;
  .ant-menu-item {
    margin-bottom: 10px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
  }
  .ant-menu-item-selected {
    background-color: #f5f5f5;
    color: #ed6928;
  }
  .ant-menu-item > .ant-menu-title-content {
    text-align: left;
    padding-left: 6px;
  }
`;
