import styled from "styled-components";
import AntInput from "uiKits/Input";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;

  @media screen and (max-width: 768px) {
    gap: 0.5rem;
    width: 100%;
  }
`;

export const StyledLabel = styled.label`
  color: var(--black-fonts-headings, #2d2e2e);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const StyledInput = styled(AntInput)`
  width: ${(props) => props.width};
  height: 3.5rem;

  @media screen and (max-width: 768px) {
    // width: 20.0625rem;
    width: ${(props) => (props.width ? "19.4rem" : "20.0625rem")};
  }
`;
