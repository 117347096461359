import React from "react";
import Line from "@/assets/Line.png";
import BoxIcon from "@/assets/BoxIcon.png";
import dropship_logo from "@/assets/dropship_logo.png";
import Truck from "@/assets/Truck.png";
import {
  MainContainer,
  InnerContainer,
  ProcessBar,
  ProcessStep,
  ProcessHeading,
  StyledUl,
  StyledLi,
  StyledUlWrapper,
  StyledIconWrapper,
  StyledIcon,
  StyledMainHeading,
  HeadingsWrapper,
  StyledSubHeading,
  StyledSpan,
  StyledImage,
  ProcessBody,
  StyledCol,
  StyledCol1,
  StyledUlEndCol,
  ProcessStepmb,
  ProcessStepmbWrapper,
} from "./styles";

const HowItWorksSection = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <HeadingsWrapper>
          <StyledMainHeading>
            {`HOW`}
            <StyledSpan>{` IT WORKS?`}</StyledSpan>
          </StyledMainHeading>
          <StyledSubHeading>{`Store your products with ECOM-DROPSHIP, and we'll handle the rest`}</StyledSubHeading>
        </HeadingsWrapper>
        <ProcessBar>
          <ProcessStep>1</ProcessStep>
          <StyledImage src={Line} alt="Line"></StyledImage>
          <ProcessStep>2</ProcessStep>
          <StyledImage src={Line} alt="Line"></StyledImage>
          <ProcessStep>3</ProcessStep>
        </ProcessBar>
        <ProcessBody>
          <StyledCol>
            <ProcessStepmbWrapper>
              <ProcessStepmb>1</ProcessStepmb>
            </ProcessStepmbWrapper>
            <StyledIconWrapper>
              <StyledIcon src={BoxIcon} alt="BoxIcon"></StyledIcon>
            </StyledIconWrapper>
            <ProcessHeading>{`Source Best Items at ECOMDROPSHIP`}</ProcessHeading>
            <StyledUl>
              <StyledLi>{`ONE-CLICK PRODUCT IMPORT`}</StyledLi>
            </StyledUl>
          </StyledCol>
          <StyledCol1>
            <ProcessStepmbWrapper>
              <ProcessStepmb>2</ProcessStepmb>
            </ProcessStepmbWrapper>
            <StyledIconWrapper>
              <StyledIcon src={dropship_logo} alt="dropship_logo"></StyledIcon>
            </StyledIconWrapper>
            <ProcessHeading>
              ECOMDROPSHIP takes <br /> care of everything
            </ProcessHeading>
            <StyledUlWrapper>
              <StyledUl>
                <StyledLi>{`Price & Inventory Sync`}</StyledLi>
                <StyledLi>{`Order Fulfillment`}</StyledLi>
                <StyledLi>{`Order Status Updates`}</StyledLi>
              </StyledUl>
              <StyledUl>
                <StyledLi>{`Warehousing (US)`}</StyledLi>
                <StyledLi>{`Quality Inspection`}</StyledLi>
                <StyledLi>{`Order Status Updates`}</StyledLi>
              </StyledUl>
            </StyledUlWrapper>
          </StyledCol1>
          <StyledCol>
            <ProcessStepmbWrapper>
              <ProcessStepmb>3</ProcessStepmb>
            </ProcessStepmbWrapper>
            <StyledIconWrapper>
              <StyledIcon src={Truck} alt="Truck"></StyledIcon>
            </StyledIconWrapper>
            <ProcessHeading>{`Shipping`}</ProcessHeading>
            <StyledUlEndCol>
              <StyledLi>{`1-DAY PROCESSING TIME`}</StyledLi>
            </StyledUlEndCol>
          </StyledCol>
        </ProcessBody>
      </InnerContainer>
    </MainContainer>
  );
};

export default HowItWorksSection;
