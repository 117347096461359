import React from "react";
import Layout from "layout";
import Checkout from "./Checkout";

const CheckoutPage = () => {
  
  return (
    <Layout>
      <Checkout />
    </Layout>
  );
};

export default CheckoutPage;
