import React from "react";

const PlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
  >
    <path
      d="M18 11.5526H13V15.9959C13 16.4846 12.55 16.8845 12 16.8845C11.45 16.8845 11 16.4846 11 15.9959V11.5526H6C5.45 11.5526 5 11.1527 5 10.6639C5 10.1752 5.45 9.77528 6 9.77528H11V5.33201C11 4.84325 11.45 4.44336 12 4.44336C12.55 4.44336 13 4.84325 13 5.33201V9.77528H18C18.55 9.77528 19 10.1752 19 10.6639C19 11.1527 18.55 11.5526 18 11.5526Z"
      fill="#6B7280"
    />
  </svg>
);

export default PlusIcon;
