import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import { Breadcrumb, Button, Divider, InputNumber } from "antd";
import AntSelect from "uiKits/Select";
import AntButton from "uiKits/Button";

export const StyledBreadCrumbs = styled(Breadcrumb)`
  width: 85%;
  margin: 0 auto;
  padding-top: 35px;
  padding-bottom: 10px;
  ol li span a {
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.6px;
  }
`;

export const HeadingText = styled.h2`
  width: 85%;
  margin: 0 auto;
  padding: 25px 0;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const BodyContainer = styled.div`
  display: flex;
  gap: 4rem;
  width: 87%;
  padding: 0 5.5rem 2.81rem 5.63rem;
`;

export const LeftContainer = styled.div`
  width: 55%;

  .image-gallery-thumbnails .image-gallery-thumbnail.active,
  .image-gallery-thumbnails .image-gallery-thumbnail:hover {
    border: 3px solid #ed6928;
  }
`;

export const ImageGalleryContainer = styled.div`
  margin-bottom: 3.6rem;
`;

export const StyledImageGallery = styled(ImageGallery)``;

export const MetaDataWrapper = styled.div`
  display: flex;
  gap: 0.94rem;
`;

export const RightContainer = styled.div`
  width: 45%;
`;

export const LabelText = styled.p`
  color: var(--Text2, #000);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  margin: 0;
`;

export const ReviewsStockWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1.19rem;
  align-items: center;
`;

export const ReviewsText = styled.p`
  color: var(--Text2, #000);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  opacity: 0.5;
  margin: 0;
`;

export const StyledDivider = styled(Divider)`
  width: 0.0625rem;
  height: 1rem;
  opacity: 0.5;
  background: #000;
`;

export const InStockText = styled.p`
  color: var(--Button1, #0f6);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  opacity: 0.6;
  margin: 0;
`;

export const CategoryText = styled.p`
  color: var(--Text2, #000);
  /* Title/14PX Regular */
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem; /* 150% */
  margin: 0;
  margin-left: 0.44rem;
  margin-top: 1rem;
`;

export const LineContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.94rem;
`;

export const LineHalf = styled.div`
  height: 0.1875rem;
`;

export const LeftHalf = styled(LineHalf)`
  background-color: #ed6928;
  flex: 4;
`;

export const RightHalf = styled(LineHalf)`
  background-color: #d2d2d2;
  flex: 6;
`;

export const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1.12rem;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.56rem;
  width: 50%;
`;

export const PriceLabel = styled.p`
  color: var(--Text2, #000);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.03rem;
  margin: 0;
`;

export const PriceValue = styled.h4`
  color: var(--Text2, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 100% */
  letter-spacing: 0.045rem;
  margin: 0;
`;

export const ProductDescription = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  margin: 0;
  padding-top: 2.37rem;
  width: 80%;
`;

export const DescriptionDivider = styled.div`
  display: flex;
  width: 100%;
  height: 0.1rem;
  background-color: #000000;
  opacity: 0.5;
  margin-top: 1.43rem;
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
`;

export const StyledLabel = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.6px;
  width: 10%;
`;

export const StyledSelect = styled(AntSelect)`
  width: 80%;
`;

export const StyledQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 47px;
  margin-top: 53px;
`;

export const StyledPlusButton = styled(AntButton)`
  width: 41px;
  height: 44px;
  border-radius: 0px 4px 4px 0px;
`;

export const StyledMinusButton = styled(AntButton)`
  width: 41px;
  height: 44px;
  border-radius: 4px 0px 0px 4px;
`;

export const StyledInputNumber = styled(InputNumber)`
  border-radius: unset;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  align-items: cennter;
`;

export const StyledBuyButton = styled(Button)`
  color: #ed6928;
  background: #ffffff;
  border-color: #ed6928;
  border-radius: 4px;
  padding: 30px 111px;
  margin-top: 40px;
  width: 100%;
  &:hover {
    color: #ffffff !important;
    background: #ed6928 !important;
    border-color: #ed6928 !important;
  }
`;
