import Layout from "layout";
import React from "react";
import ProductList from "./ProductList";

const ProductListPage = () => {
  return (
    <Layout>
      <ProductList />
    </Layout>
  );
};

export default ProductListPage;
