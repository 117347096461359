import { Button } from "antd";
import styled from "styled-components";
import AntInput from "uiKits/Input";
import AntSelect from "uiKits/Select";
import PhoneInput from "react-phone-number-input";
import { CountryDropdown } from "react-country-region-selector";

export const MainContainer = styled.div`
  border-left: 1px solid #dfe3e8;
  @media (max-width: 768px) {
    border-left: 0px;
  }
`;

export const InnerContainer = styled.div`
  padding-left: 60px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

export const StyledSelect = styled(AntSelect)`
  width: 100%;
  height: 40px;
  .ant-select-selection-item {
    color: #585858;
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    text-transform: capitalize;
  }
`;

export const MainHeading = styled.h3`
  margin: 0px;
  color: #000;
  text-align: left;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: capitalize;
  margin-bottom: 25px;
`;

export const StyledRow = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledPhoneRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 33%;
`;

export const StyledTitle = styled.h4`
  margin: 0px;
  color: #585858;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 30px;
`;

export const StyledButton = styled.a`
  color: #ed6928;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  text-transform: capitalize;
  line-height: 80px;
`;

export const StyledInput = styled(AntInput)`
  padding: 8px 11px;
  background-color: #fff;
  border-color: #dfe3e8;
`;

export const StyledBottomWrapper = styled.div`
  margin-top: 50px;
`;

export const StyledInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #dfe3e8;
  background: #fff;
  padding: 30px;
`;

export const StyledSubHeading = styled.h5`
  margin: 0px;
  color: #585858;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  text-transform: capitalize;
`;

export const StyledSubHeading2 = styled.h6`
  margin: 0px;
  color: #ed6928;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: capitalize;
`;

export const StyledUploadButton = styled(Button)`
  height: 50px;
  padding: 0px 25px;
  border-radius: 4px;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #ed6928;
  color: #fff;
`;

export const StyledPhone = styled(PhoneInput)`
  .PhoneInputInput {
    height: 35px;
    font-size: 16px;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    &:focus-visible {
      outline-color: #ed6928 !important;
    }
  }
`;
export const StyledCountry = styled(CountryDropdown)`
  height: 40px;
  width: 100%;
  padding-left: 10px;
  color: #585858;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 4px;
  border-color: #dfe3e8;
  &:focus-visible {
    outline-color: #ed6928 !important;
  }
`;
