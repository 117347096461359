import React from "react";
import { Form } from "antd";
import {
  StyledHeading,
  StyledSubHeading,
  MainLayout,
  StyledCheckbox,
  CheckboxWrapper,
  FlexContainer,
  StyledButton,
  ButtonWrapper,
  StyledSelect,
  StyledLabel,
} from "./styles";

const ThirdStep = ({
  setStepsPayload,
  stepsPayload,
  handleUpdateDropshipper,
}) => {
  const [form] = Form.useForm();
  const plainOptions = [
    {
      label: "Women's Clothing",
      value: "Women's Clothing",
    },
    {
      label: "Men's Clothing",
      value: "Men's Clothing",
    },
    {
      label: "Accessories",
      value: "Accessories",
    },
    {
      label: "Beauty and Fragrances",
      value: "Beauty and Fragrances",
    },
    {
      label: "Footwear",
      value: "Footwear",
    },
    {
      label: "other",
      value: "other",
    },
  ];

  const infoSelectOptions = [
    {
      value: "TikTok App Store",
      label: "TikTok App Store",
    },
    {
      value: "Google Search",
      label: "Google Search",
    },
    {
      value: "SocialMedia & Blogs",
      label: "SocialMedia & Blogs",
    },
    {
      value: "Word of Mouth (Friends or Family)",
      label: "Word of Mouth (Friends or Family)",
    },
    {
      value: "ECOMDROPSHIP Representative",
      label: "ECOMDROPSHIP Representative",
    },
    {
      value: "Offline Events",
      label: "Offline Events",
    },
  ];

  const durationSelectOptions = [
    {
      value: "<1 Year",
      label: "<1 Year",
    },
    {
      value: "1 - 3 Years",
      label: "1 - 3 Years",
    },
    {
      value: "3 - 5 Years",
      label: "3 - 5 Years",
    },
    {
      value: "5+ Years",
      label: "5+ Years",
    },
  ];

  const onCheckboxChange = (checkedValues) => {
    setStepsPayload({
      ...stepsPayload,
      storeInfo: {
        ...stepsPayload.storeInfo,
        sellCategories: checkedValues,
      },
    });
  };

  const handleFinish = (values) => {
    setStepsPayload({
      ...stepsPayload,
      storeInfo: {
        ...stepsPayload.storeInfo,
        hereAboutUs: values.hereAboutUs,
        howLong: values.howLong,
      },
    });
    handleUpdateDropshipper({
      ...stepsPayload,
      storeInfo: {
        ...stepsPayload.storeInfo,
        hereAboutUs: values.hereAboutUs,
        howLong: values.howLong,
      },
    });
  };
  return (
    <MainLayout>
      <StyledHeading>Now Let's Get to Know Your Business</StyledHeading>
      <StyledSubHeading>
        *What categories do you want to sell? (select all that apply)
      </StyledSubHeading>
      <CheckboxWrapper>
        <StyledCheckbox options={plainOptions} onChange={onCheckboxChange} />
      </CheckboxWrapper>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <FlexContainer>
          <Form.Item
            label={
              <StyledLabel>How did you here about ECOMDROPSHIP?</StyledLabel>
            }
            name="hereAboutUs"
            rules={[]}
          >
            <StyledSelect
              placeholder="Please Select"
              options={infoSelectOptions}
            />
          </Form.Item>
          <Form.Item
            label={
              <StyledLabel>How long have you been in business?</StyledLabel>
            }
            name="howLong"
            rules={[]}
          >
            <StyledSelect
              placeholder="Please Select"
              options={durationSelectOptions}
            />
          </Form.Item>
        </FlexContainer>
        <ButtonWrapper>
          <StyledButton htmlType="submit">Continue</StyledButton>
        </ButtonWrapper>
      </Form>
    </MainLayout>
  );
};

export default ThirdStep;
