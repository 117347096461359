import Layout from "layout";
import React from "react";
import SupplierMain from "@/assets/Suppliermain.png";
import {
  BannerWrapper,
  InnerContainer,
  MainBanner,
  MainContainer,
  MainHeading,
  MainSubHeading,
} from "./styles";
import SupplierForm from "./SupplierForm";

const SupplierPage = () => {
  return (
    <MainContainer>
      <BannerWrapper>
        <MainBanner src={SupplierMain} />
      </BannerWrapper>
      <InnerContainer>
        <MainHeading>{`Become a supplier on ECOMDROPSHIP`}</MainHeading>
        <MainSubHeading>{`We appreciate your interest to become a supplier on ECOMDROPSHIP`}</MainSubHeading>
        <MainSubHeading>{`Please provide some basic information`}</MainSubHeading>
        <SupplierForm />
      </InnerContainer>
    </MainContainer>
  );
};

export default SupplierPage;
