import styled from "styled-components";
import AntCheckbox from "uiKits/Checkbox";
import AntInput from "uiKits/Input";
import AntRadioButton from "uiKits/RadioButons";

export const MainContainer = styled.div``;

export const InnerContainer = styled.div`
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
`;

export const MainHeading = styled.h3`
  margin: 20px 0px 20px 0px;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const InputWrapper = styled.div`
  padding-top: 20px;
`;

export const StyledInput = styled(AntInput)`
  background: #fff;
  height: 42px;
`;

export const CheckBoxWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;
`;

export const StyledCheckBox = styled(AntCheckbox)`
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
  }
  .ant-checkbox-wrapper {
    font-size: 18px;
  }
`;

export const RadioWrapper = styled.div``;

export const RadioInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 50px;
  margin-top: 20px;
  label {
    font-size: 18px;
  }
`;

export const StyledRadioButton = styled(AntRadioButton)`
  .ant-radio-inner {
    width: 22px;
    height: 22px;
  }
  .ant-radio-wrapper {
    font-size: 18px;
  }
`;

export const StyledCurrentAddress = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
