import styled from "styled-components";

export const MainContainer = styled.div`
  width: 24%;
  border-radius: 10px;
  background: #fafafa;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 47%;
  }
`;

export const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  &:before {
    white-space: pre;
    content: "TIKTOK \\A VERIFIED";
    position: absolute;
    border-radius: 0px 9999px 9999px 0px;
    background: rgba(237, 105, 40, 0.7);
    top: 45px;
    left: 0;
    padding: 10px;
    color: #fff;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
  }
  @media screen and (max-width: 768px) {
    &:before {
      top: 22px;
      padding: 8px;
      font-size: 6px;
      line-height: 10px;
    }
  }
`;

export const ImageWrapper = styled.div`
  padding-top: 80px;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 70%;
    padding-top: 45px;
  }
`;

export const StyledImage = styled.img`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 12px;
  @media screen and (max-width: 768px) {
    margin-top: 25px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
`;

export const InnerPriceWrapper = styled.div`
  padding-bottom: 26px;
  display: flex;
  gap: 6px;
`;

export const PriceWrapperAfterLogin = styled.div`
  padding-bottom: 26px;
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: space-between;
`;

export const MsrpPriceWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const StyledTitle = styled.h3`
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const StyledPrice = styled.span`
  color: #6b7280;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledPriceAfterLogin = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;
