import * as React from "react";

const MetaPlay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
  >
    <circle cx="18" cy="18" r="18" fill="white" />
    <path
      d="M23.82 18.523C23.8902 18.4128 23.9375 18.2896 23.9592 18.1608C23.9809 18.032 23.9766 17.9001 23.9463 17.773C23.9161 17.6458 23.8607 17.5261 23.7833 17.4208C23.706 17.3155 23.6083 17.2268 23.496 17.16L13.574 11.128C13.4127 11.0303 13.2276 10.9788 13.039 10.979C12.479 10.979 12.026 11.422 12.026 11.969V24.03C12.026 24.215 12.079 24.396 12.179 24.553C12.475 25.017 13.099 25.159 13.574 24.87L23.496 18.839C23.627 18.759 23.739 18.65 23.821 18.522L23.82 18.523ZM24.566 20.52L14.645 26.551C13.22 27.418 11.345 26.991 10.459 25.6C10.1594 25.1311 10.0002 24.5864 10 24.03V11.97C10 10.329 11.36 9 13.04 9C13.607 9 14.163 9.155 14.645 9.448L24.566 15.48C25.991 16.346 26.428 18.176 25.541 19.568C25.295 19.954 24.961 20.28 24.566 20.52Z"
      fill="#ED6928"
    />
  </svg>
);

export default MetaPlay;
