import styled from "styled-components";

export const MainContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: 100px;
`;

export const InnerContainer = styled.div`
  display: flex;
  gap: 50px;
`;

export const LeftCol = styled.div`
  width: 60%;
`;
export const RightCol = styled.div`
  width: 40%;
`;

export const MainHeading = styled.h2`
  margin: 0;
  padding-bottom: 50px;
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
