import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "@/components/LandingPage";
import SignUp from "@/components/SignUp";
import PricingPage from "@/components/PricingPage";
import BestSellingProductListPage from "@/components/BestSellingProductListPage";
import ProfilePage from "./components/ProfilePage";
import ProductDetail from "./components/ProductDetail";
import MarketingPage from "@/components/MarketingPage";
import cache from "utils/cache";
import PrivateRoutes from "routes/PrivateRoutes";
import PublicRoutes from "routes/PublicRoutes";
import { useVerifyUserQuery } from "api/authApi";
import Loader from "shared/Loader";
import OrdersDetailPage from "./components/OredrsDetailPage";
import CartPage from "./components/CartPage";
import SupplierPage from "./components/SupplierPage";
import ProductListPage from "./components/ProductListPage";
import AddProductPage from "./components/AddProductPage";
import ThirdPartyProductsPage from "./components/ThirdPartyProductsPage";
import CheckoutPage from "./components/CheckoutPage";
import OrderSuccessPage from "./components/OrderSuccessPage";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import OrderPayment from "./components/CheckoutPage/OrderPayment";

function App() {
  const { data: user, isLoading } = useVerifyUserQuery();
  const clientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

  if (isLoading) return <Loader isLoading={isLoading} />;

  if (user) {
    cache.setItem("user", user);
  } else {
    cache.removeItem("user");
  }

  // Initial options for the PayPal SDK
  const initialOptions = {
    "client-id": clientID,
    currency: "USD",
    "disable-funding": "paylater",
    vault: true,
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <Router>
        <Routes>
          <Route path="*" element="Coming Soon" />
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<MarketingPage />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/landing_page" element={<LandingPage />} />
            <Route path="/orders__detail_page" element={<OrdersDetailPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/supplier_page" element={<SupplierPage />} />
            <Route path="/product_list_Page" element={<ProductListPage />} />
            <Route path="/add_product" element={<AddProductPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/order_success_page" element={<OrderSuccessPage />} />
            <Route path="/order_payment" element={<OrderPayment />} />
            <Route
              path="/third_party_products"
              element={<ThirdPartyProductsPage />}
            />
          </Route>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route
            path="/best_selling"
            element={<BestSellingProductListPage />}
          />
          <Route
            path="/third_party_products"
            element={<ThirdPartyProductsPage />}
          />
          <Route path="/product/:id" element={<ProductDetail />} />
        </Routes>
      </Router>
    </PayPalScriptProvider>
  );
}

export default App;
