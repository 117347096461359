import React from "react";

const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M19.6875 8.3125L8.3125 19.6875M8.3125 8.3125L19.6875 19.6875"
      stroke="#585858"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CrossIcon;
