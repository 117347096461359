import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const InnerContainer = styled.div`
  width: 90%;
  padding-top: 20px;
  margin: 0 auto;
`;

export const HeadingsWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledSubHeading = styled.h5`
  margin: 0px;
  color: #585858;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 38.4px */
  padding-top: 16px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const ProcessBar = styled.div`
  display: flex;
  margin-top: 100px;
  gap: 5%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ProcessBody = styled.div`
  display: flex;
  margin-top: 50px;
  gap: 5%;
  align-items: baseline;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    gap: 20px;
    align-items: center;
    flex-direction: column;
  }
`;

export const ProcessHeading = styled.h3`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 138%; /* 33.12px */
  padding-top: 28px;
`;

export const StyledUlWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul li {
    padding-top: 20px;
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    ul li {
      padding-top: 10px;
    }
    ul {
      padding-top: 0px;
    }
    flex-direction: column;
  }
`;

export const StyledUl = styled.ul`
  margin: 0px;
  padding: 10px;
  padding-top: 30px;
`;

export const StyledUlEndCol = styled.ul`
  margin: 0px;
  padding: 10px;
  padding-top: 60px;
  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }
`;

export const StyledLi = styled.li`
  color: #585858;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 138%; /* 22.08px */
`;

export const ProcessStep = styled.span`
  background: #ed6928;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProcessStepmbWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;
export const ProcessStepmb = styled.span`
  background: #ed6928;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.img``;

export const StyledIconWrapper = styled.div`
  width: 200px;
  margin: 0 auto;
`;

export const StyledIcon = styled.img`
  width: 100%;
`;

export const StyledCol = styled.div`
  width: 20%;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 85%;
  }
`;

export const StyledCol1 = styled.div`
  width: 40%;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 85%;
  }
`;

export const StyledMainHeading = styled.h2`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const StyledSpan = styled.span`
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
  color: #ed6928;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
