import { Link } from "react-router-dom";
import { Table } from "antd";

export const columns = [
  {
    title: "Customer Name",
    key: "customer_name",
    dataIndex: "customer",
    width: "15%",
  },
  {
    title: "Items",
    key: "items",
    dataIndex: "items",
    width: "15%",
  },
  {
    title: "Tracking Id",
    key: "tracking_id",
    dataIndex: "orderTrackingId",
    width: "15%",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    width: "15%",
  },
];
