import React from "react";

const AddedProductIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 21.0001L20.131 16.7921C20.447 16.6281 20.605 16.5471 20.72 16.4261C20.8219 16.3199 20.899 16.1926 20.946 16.0531C21 15.8941 21 15.7171 21 15.3611V7.53313M12 21.0001L3.869 16.7921C3.553 16.6281 3.395 16.5471 3.28 16.4261C3.17838 16.3198 3.10124 16.1925 3.054 16.0531C3 15.8941 3 15.7161 3 15.3591V7.53313M12 21.0001V11.9371M21 7.53313L12 11.9371M21 7.53313L12.73 3.25313C12.463 3.11513 12.33 3.04513 12.189 3.01813C12.0642 2.99396 11.9358 2.99396 11.811 3.01813C11.671 3.04513 11.537 3.11513 11.269 3.25313L3 7.53313M3 7.53313L12 11.9371"
      stroke="#9CA3AF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default AddedProductIcon;
