import React from "react";
import HeroSection from "./HeroSection";
import Header from "./Header";
import AnnouncementBar from "./AnnouncementBar";
import VideosSection from "./VideosSection";
import ImageWithTextSection from "./ImageWithTextSection";
import HowItWorksSection from "./HowItWorkSection";
import Footer from "./Footer";
import VideoPlayer from "../VideoPlayer";
import { VideoContainer } from "./styles";

const MarketingPage = () => {
  return (
    <>
      <AnnouncementBar />
      <Header />
      <HeroSection />
      <VideosSection />
      <VideoContainer>
        <VideoPlayer url="https://ecom-dropship-bucket.s3.amazonaws.com/VID-20240715-WA0001.mp4" />
      </VideoContainer>
      <ImageWithTextSection />
      <HowItWorksSection />
      <Footer />
    </>
  );
};

export default MarketingPage;
