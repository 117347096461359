import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Inter', Poppins;
    margin: 0;
    padding: 0
  }
  `;

export default GlobalStyles;
