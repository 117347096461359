import styled from "styled-components";

export const MainContainer = styled.div`
  width: 95%;
  margin: 0 auto;
`;

export const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const StyledHeading = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:before {
    content: "";
    padding: 0px 2px 0px 20px;
    border-radius: 4px;
    margin-right: 20px;
    background-color: #ed6928;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
    &:before {
      padding: 0px 2px 0px 10px;
      margin-right: 14px;
    }
  }
`;

export const PaginationWrapper = styled.div`
  text-align: center;
  margin-top: 60px;
`;
