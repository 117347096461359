import React from "react";
import AnnouncementBar from "../MarketingPage/AnnouncementBar";
import Header from "../MarketingPage/Header";
import Footer from "../MarketingPage/Footer";
import ProductSection from "../BestSellingProductListPage/ProductSection";
import NewArrivalSection from "../BestSellingProductListPage/NewArrivalSection";
import IconWithTextSection from "../BestSellingProductListPage/IconWithTextSection";
import HeroBannerCarousel from "./HeroBannerCarousel";
import { useGetProductsQuery } from "api/productApi";
import Loader from "shared/Loader";

const LandingPage = () => {
  const { data: bestSellingData, isLoading } =
    useGetProductsQuery("BEST_SELLING");
  return (
    <>
      <AnnouncementBar />
      <Header />
      <HeroBannerCarousel />
      <ProductSection
        heading="Latest Products"
        products={bestSellingData?.products}
      />
      <NewArrivalSection />
      <IconWithTextSection />
      <Footer />
      <Loader isLoading={isLoading} />
    </>
  );
};

export default LandingPage;
