import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 140px;
  padding-top: 40px;
  background: var(--Button, #000);
`;

export const InnerContainer = styled.div`
  width: 90%;
  padding-top: 20px;
  margin: 0 auto;
`;

export const FooterHeading = styled.h3`
  padding-bottom: 70px;
  margin: 0;
  color: #fafafa;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 87.5% */
  text-align: center;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const FooterHeadingmb = styled.h3`
  margin: 0;
  color: #fafafa;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const FooterBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoWrapper = styled.div`
  width: 25%;
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterLogo = styled.img``;

export const MenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 15%;
  @media screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

export const StyledLink = styled.a`
  padding-bottom: 25px;
  color: #fafafa;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
`;

export const FooterTextWrapper = styled.div`
  width: 33%;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const FooterText = styled.p`
  margin: 0px;
  color: #fafafa;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
`;
