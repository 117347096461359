import React, { useState, useEffect } from "react";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { insertKey } from "utils/helpers";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import ExpandedRowTableContent from "../ExpandedRowTableContent";
import {
  InnerContainer,
  MainContainer,
  MainHeading,
  MainSubHeading,
  StyledButton,
  StyledTable,
  TableWrapper,
} from "./styles";
import { useGetSupplierProductsQuery } from "api/productApi";

const ProductList = () => {
  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState([]); // State to hold processed table data
  const { data: addedProducts } = useGetSupplierProductsQuery();
  console.log("addedProducts", addedProducts);
  const navigate = useNavigate();

  // Apply insertKey to the products data whenever addedProducts changes
  useEffect(() => {
    if (addedProducts?.products) {
      setTableData(insertKey(addedProducts.products)); // Apply insertKey function here
    }
  }, [addedProducts]);

  const handleAddProductClick = () => {
    navigate("/add_product");
  };

  return (
    <MainContainer>
      <InnerContainer>
        <div>
          <MainHeading>{`Added Products`}</MainHeading>
          <MainSubHeading>{`You can check your added products here.`}</MainSubHeading>
        </div>
        <StyledButton
          onClick={handleAddProductClick}
        >{`Add Product`}</StyledButton>
      </InnerContainer>
      <TableWrapper>
        <StyledTable
          columns={columns}
          dataSource={tableData} // Use processed tableData
          pagination={true}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <ExpandedRowTableContent
                  description={record.description}
                  msrp={record.MSRP}
                />
              );
            },
            expandRowByClick: false,
            expandIcon: ({ expanded, onExpand, record }) => {
              return expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightOutlined onClick={(e) => onExpand(record, e)} />
              );
            },
            expandedRowKeys: expanded,
            onExpand: (expanded, record) => {
              const keys = [];
              if (expanded) {
                keys.push(record.key);
              }
              setExpanded(keys);
            },
          }}
        />
      </TableWrapper>
    </MainContainer>
  );
};

export default ProductList;
