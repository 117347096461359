export const getItem = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const setItem = (key, value) => {
  return window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key) => {
  return window.sessionStorage.removeItem(key);
};

const cache = {
  getItem,
  setItem,
  removeItem,
};

export default cache;
