import React from 'react'
import video1 from '@/assets/video1.png'
import video2 from '@/assets/video2.png'
import video3 from '@/assets/video3.png'

import {
  MainContainer,
  InnerContainer,
  VideosWrapper,
  VideoWrapper,
  VideoHeading,
  VideoSubHeading,
  StyledImage,
  StyledMainHeading,
  HeadingsWrapper,
  StyledSpan,
  VideosWrappermb,
  StyledImageWrapper,
  CarouselWrapper
} from './styles'

const VideosSection = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <HeadingsWrapper>
          <StyledMainHeading>
            {`A Great Opportunity to sell to 1.1 Billion Users on`}
            <StyledSpan>{` TikTok SHOP`}</StyledSpan>
          </StyledMainHeading>
        </HeadingsWrapper>

        <VideosWrapper>
          <VideoWrapper>
            <StyledImage src={video1} alt='video1'></StyledImage>
            <VideoHeading>{`SHOPPABLE VIDEO`}</VideoHeading>
            <VideoSubHeading>{`Maximize your sales with TikTok Creators.`}</VideoSubHeading>
          </VideoWrapper>
          <VideoWrapper>
            <StyledImage src={video2} alt='video2'></StyledImage>
            <VideoHeading>{`SHOP PAGE`}</VideoHeading>
            <VideoSubHeading>{`Display your product catalog within your own in-app shop.`}</VideoSubHeading>
          </VideoWrapper>
          <VideoWrapper>
            <StyledImage src={video3} alt='video3'></StyledImage>
            <VideoHeading>{`SHOPPABLE VIDEO`}</VideoHeading>
            <VideoSubHeading>{`Display your products on a marketplace within TikTok.`}</VideoSubHeading>
          </VideoWrapper>
        </VideosWrapper>

        <VideosWrappermb>
          <CarouselWrapper>
            <VideoWrapper>
              <StyledImageWrapper>
                <StyledImage src={video1} alt='video1'></StyledImage>
              </StyledImageWrapper>
              <VideoHeading>{`SHOPPABLE VIDEO`}</VideoHeading>
              <VideoSubHeading>{`Maximize your sales with TikTok Creators.`}</VideoSubHeading>
            </VideoWrapper>
            <VideoWrapper>
              <StyledImageWrapper>
                <StyledImage src={video2} alt='video2'></StyledImage>
              </StyledImageWrapper>
              <VideoHeading>{`SHOP PAGE`}</VideoHeading>
              <VideoSubHeading>{`Display your product catalog within your own in-app shop.`}</VideoSubHeading>
            </VideoWrapper>
            <VideoWrapper>
              <StyledImageWrapper>
                <StyledImage src={video3} alt='video3'></StyledImage>
              </StyledImageWrapper>
              <VideoHeading>{`SHOPPABLE VIDEO`}</VideoHeading>
              <VideoSubHeading>{`Display your products on a marketplace within TikTok.`}</VideoSubHeading>
            </VideoWrapper>
          </CarouselWrapper>
        </VideosWrappermb>
      </InnerContainer>
    </MainContainer>
  )
}

export default VideosSection
