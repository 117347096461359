import Layout from "layout";
import React from "react";
import OrdersDetail from "./OredrDetail";

const OrdersDetailPage = () => {
  return (
    <Layout>
      <OrdersDetail />
    </Layout>
  );
};

export default OrdersDetailPage;
