import React, { useState } from "react";
import {
  CheckBoxWrapper,
  InnerContainer,
  InputWrapper,
  MainContainer,
  MainHeading,
  RadioInnerWrapper,
  RadioWrapper,
  StyledCheckBox,
  StyledCurrentAddress,
  StyledInput,
  StyledRadioButton,
} from "./styles";
import AddressForm from "../AddressForm";
import { useGetShippingAddressQuery } from "api/authApi";
import cache from "utils/cache";

const AddressPaymentDetails = () => {
  const [selectedValue, setSelectedValue] = useState("Current");

  const { data: shippingdetails } = useGetShippingAddressQuery();
  const user = cache.getItem("user");

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <MainContainer>
      <InnerContainer>
        {/* <MainHeading>{`Enter Payment Details`}</MainHeading>
        <PayPalButtons
          style={{
            layout: "vertical",
            color: "gold",
            shape: "rect",
            label: "paypal",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: "20.00",
                  },
                },
              ],
            });
          }}
          onApprove={handleApprove}
          onError={(err) => console.error("PayPal Checkout Error:", err)}
        /> */}
        <MainHeading>{`Shipping Address`}</MainHeading>
        <RadioWrapper>
          <RadioInnerWrapper>
            <StyledRadioButton.Group
              onChange={handleRadioChange}
              value={selectedValue}
            >
              <StyledRadioButton value="Current">
                Use Current Address
              </StyledRadioButton>
              <StyledRadioButton value="New">New Address</StyledRadioButton>
            </StyledRadioButton.Group>
          </RadioInnerWrapper>

          <div style={{ marginTop: "20px" }}>
            {selectedValue === "Current" && (
              <>
                <StyledCurrentAddress>{`${user?.firstName} ${user?.lastName}`}</StyledCurrentAddress>
                <StyledCurrentAddress>{`${user?.phoneNumber}`}</StyledCurrentAddress>
                <StyledCurrentAddress>{`${shippingdetails?.state} - ${shippingdetails?.appartmentNumber} , ${shippingdetails?.streetAddress} , ${shippingdetails?.zip}`}</StyledCurrentAddress>
              </>
            )}
            {selectedValue === "New" && <AddressForm />}
          </div>
        </RadioWrapper>
      </InnerContainer>
    </MainContainer>
  );
};

export default AddressPaymentDetails;
