import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Input, Dropdown, Badge } from "antd";
import SignInModal from "../SignInModal";
import dropship_logo from "@/assets/dropship_logo.png";
import {
  MainContainer,
  InnerContainer,
  LogoWrapper,
  StyledLogo,
  MenuWrapperDesktop,
  SearchWrapper,
  ButtonsWrapper,
  LoginButton,
  SignupButton,
  MenuWrapperMobile,
  StyledBarsWrapper,
  SyledBarsmb,
  StyledClosemb,
  StyledLink,
  StyledDropdown,
  IconWrapper,
  StyledDivider,
  StyledMenuLink,
  StyledMenuContainer,
  ImageUserName,
  MenuImage,
  MenuText,
  MenuUserName,
  MenuEmail,
} from "./styles";
import ProductsDropdown from "./productsDropdown";
import cache from "utils/cache";
import UserIcon from "@/assets/icons/userIcon";
import CartIcon from "@/assets/icons/cartIcon";
import MyOrdersIcon from "@/assets/icons/myOrdersIcon";
import AddedProductIcon from "@/assets/icons/addedProductsIcon";
import SignOutIcon from "@/assets/icons/signOutIcon";
import { truncateWithEllipsis } from "utils/helpers";
import { useLogoutUserMutation } from "api/authApi";

const { Search } = Input;

const Header = () => {
  const user = cache.getItem("user");
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [logoutUser] = useLogoutUserMutation();

  const [cartItems, setCartItems] = useState(() => {
    return cache.getItem("cartItems") || [];
  });
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState("mail");
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false); // New state for sign-up

  const maxLength = 14;
  const email = user?.email;
  const truncatedEmail = truncateWithEllipsis(email, maxLength);

  // Sync cart items with cache whenever they change
  const syncCartItemsWithCache = () => {
    const cachedItems = cache.getItem("cartItems");
    if (cachedItems !== cartItems) {
      setCartItems(cachedItems);
    }
  };

  useEffect(() => {
    syncCartItemsWithCache();
    const intervalId = setInterval(syncCartItemsWithCache, 1000); // Check every second

    return () => clearInterval(intervalId);
  }, [cartItems]);

  const getAuthMenuItem = () => {
    return user
      ? {
          label: "Sign Out",
          key: "logout",
        }
      : {
          label: "Sign In",
          key: "login",
        };
  };

  const MobileItems = [
    {
      label: "Product",
      key: "Product",
      children: [
        {
          label: "Best Selling Items",
          key: "/best_selling",
        },
        {
          label: "Third Party Items",
          key: "/third_party_products",
        },
        {
          label: "Private Sourcing",
          key: "privateSourcing",
        },
      ],
    },
    { type: "divider" },
    {
      label: <a href="#">Resources</a>,
      key: "/resources",
    },
    { type: "divider" },
    {
      label: <StyledLink to="/pricing">Pricing</StyledLink>,
      key: "/pricing",
    },
    { type: "divider" },
    {
      label: "Settings",
      key: "/profile",
    },
    { type: "divider" },
    {
      label: "Orders",
      key: "/orders__detail_page",
    },
    { type: "divider" },
    {
      label: "Added Products",
      key: "/product_list_Page",
    },
    { type: "divider" },
    getAuthMenuItem(),
  ];

  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };

  const handleClick = (e) => {
    if (e.key === "login") {
      setIsSignUp(false); // Ensure it's set to sign in
      toggleSignInModal();
    } else if (e.key === "logout") {
      handleLogout();
    } else {
      setCurrent(e.key);
      navigate(e.key);
    }
  };

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    const res = await logoutUser();
    if (res?.data) {
      cache.removeItem("user");
      navigate("/");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSignUpClick = (isSignUp = false) => {
    setIsSignUp(isSignUp); // Set to sign up
    toggleSignInModal();
  };

  return (
    <MainContainer>
      <InnerContainer>
        <LogoWrapper>
          <StyledLogo
            onClick={() => navigate("/")}
            src={dropship_logo}
            alt="LOGO"
          />
        </LogoWrapper>
        <MenuWrapperDesktop>
          <StyledDropdown
            placement="bottom"
            dropdownRender={() => <ProductsDropdown />}
          >
            <StyledLink>Product</StyledLink>
          </StyledDropdown>
          <StyledLink>Resources</StyledLink>
          <StyledLink to="/pricing">Pricing</StyledLink>
        </MenuWrapperDesktop>
        <StyledBarsWrapper>
          <SyledBarsmb onClick={openMenu} />
        </StyledBarsWrapper>
        <MenuWrapperMobile isOpen={isOpen} ref={containerRef}>
          <StyledClosemb onClick={closeMenu} />
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="inline"
            items={MobileItems}
          />
        </MenuWrapperMobile>
        <SearchWrapper>
          <Search
            placeholder="What are you looking for?"
            onSearch={onSearch}
            style={{
              width: 250,
            }}
          />
        </SearchWrapper>
        <ButtonsWrapper>
          {user ? (
            <>
              <IconWrapper>
                <Dropdown
                  dropdownRender={() => (
                    <div>
                      <StyledMenuContainer>
                        <ImageUserName>
                          <MenuImage src={dropship_logo} />
                          <div>
                            <MenuText>{`HI, `}</MenuText>
                            <MenuUserName>{user?.firstName}</MenuUserName>
                            <MenuEmail>{truncatedEmail}</MenuEmail>
                          </div>
                        </ImageUserName>
                        <div>
                          <IconWrapper>
                            <MyOrdersIcon />
                            <StyledMenuLink href="/orders__detail_page">
                              {`My Orders`}
                            </StyledMenuLink>
                          </IconWrapper>
                        </div>
                        <StyledDivider />
                        <div>
                          <IconWrapper>
                            <AddedProductIcon />
                            <StyledMenuLink href="/product_list_Page">{`Added Products`}</StyledMenuLink>
                          </IconWrapper>
                        </div>
                        <StyledDivider />
                        <div>
                          <IconWrapper>
                            <UserIcon color="#9CA3AF" />
                            <StyledMenuLink href="/profile">{`Profile`}</StyledMenuLink>
                          </IconWrapper>
                        </div>
                        <StyledDivider />
                        <div onClick={handleLogout}>
                          <IconWrapper>
                            <SignOutIcon />
                            <StyledMenuLink>{`Sign Out`}</StyledMenuLink>
                          </IconWrapper>
                        </div>
                      </StyledMenuContainer>
                    </div>
                  )}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <UserIcon color="#ED6928" />
                  </a>
                </Dropdown>
              </IconWrapper>
              <IconWrapper>
                <a href="/cart">
                  <Badge count={cartItems?.length} offset={[5, -5]}>
                    <CartIcon />
                  </Badge>
                </a>
              </IconWrapper>
            </>
          ) : (
            <>
              <LoginButton onClick={() => handleSignUpClick(false)}>
                Login
              </LoginButton>
              <SignupButton onClick={() => handleSignUpClick(true)}>
                Sign up for Free
              </SignupButton>
            </>
          )}
        </ButtonsWrapper>
      </InnerContainer>
      {signInModalOpen && (
        <SignInModal
          isModalOpen={signInModalOpen}
          toggleModal={toggleSignInModal}
          isSignUpMode={isSignUp}
        />
      )}
    </MainContainer>
  );
};

export default Header;
