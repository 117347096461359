import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "api/authApi";
import { pricingApi } from "api/pricingApi";
import { productApi } from "api/productApi";
import { orderApi } from "api/orderApi";

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [pricingApi.reducerPath]: pricingApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(productApi.middleware)
      .concat(pricingApi.middleware)
      .concat(orderApi.middleware),
});

export default store;
