import styled from "styled-components";
import AntButton from "uiKits/Button";
import AntInput from "uiKits/Input";

export const MainContainer = styled.div`
  border-left: 1px solid #dfe3e8;
  @media (max-width: 768px) {
    border-left: none;
  }
`;

export const InnerContainer = styled.div`
  padding-left: 60px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.h1`
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
  text-transform: capitalize;
`;

export const Section = styled.div`
  margin-top: 20px;
`;

export const SectionHeader = styled.h2`
  color: #444;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PlanContainer = styled.div`
  background: rgba(239, 248, 250, 0.44);
  border-radius: 8px;
  padding: 20px;
`;

export const PlanDetails = styled.div`
  width: 100%;
`;

export const PlanName = styled.p`
  color: #ed6928;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PlanDes = styled.p`
  color: #6b7280;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlanPrice = styled.span`
  color: #ed6928;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Price = styled.span`
  color: #444;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const PricePeriod = styled.sub`
  color: #444;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const PlanIncludesWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: start;
  gap: 14px;
`;

export const PlanIncludes = styled.p`
  color: #ed6928;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PlanIncludesDes = styled.p`
  color: #6b7280;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const StyledButton = styled(AntButton)`
  height: 40px;
`;

export const PaymentContainer = styled.div`
  margin-top: 20px;
`;

export const CardDetails = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 100px;
  align-items: start;
`;

export const CardInfo = styled.div``;

export const CardType = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-top: 15px;
`;

export const CardExpiry = styled.div`
  color: red;
`;

export const EditButton = styled.button`
  background: none;
  border: none;
  color: orange;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FormContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

export const SaveButton = styled.button`
  background: orange;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background: darkorange;
  }
`;

export const StyledInput = styled(AntInput)``;

export const EditWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const CardWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

export const CardEnding = styled.p`
  color: #444;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DetailsWrapper = styled.div`
  border-right: 1px solid rgba(196, 196, 196, 0.59);
  height: 120px;
  padding-right: 50px;
  padding-top: 40px;
`;

export const CardsDetails = styled.div`
  color: #ed6928;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
