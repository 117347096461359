import styled from "styled-components";

export const MainContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-top: 90px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  gap: 25px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export const StyledHeading = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:before {
    content: "";
    padding: 0px 2px 0px 20px;
    border-radius: 4px;
    margin-right: 20px;
    background-color: #ed6928;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
    &:before {
      padding: 0px 2px 0px 10px;
      margin-right: 14px;
    }
  }
`;

export const Col1 = styled.div`
  position: relative;
  width: 42%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Col2 = styled.div`
  width: 59%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ColWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Col3 = styled.div`
  position: relative;
  width: 47%;
`;

export const Col4 = styled.div`
  position: relative;
  width: 49%;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
`;

export const StyledImg = styled.img`
  width: 100%;
`;

export const TextCard = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;

export const StyledTitle = styled.h3`
  margin: 0px;
  color: #fafafa;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.72px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const StyledSubTitle = styled.p`
  margin: 0px;
  color: #fafafa;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 242px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
    margin-bottom: 2px;
    width: unset;
  }
`;

export const StyledButton = styled.a`
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
