import Layout from "layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import orderCompleted from "@/assets/order-completed.png";
import {
  MainContainer,
  StyledButton,
  StyledHeading,
  StyledImage,
} from "./styles";

const OrderSuccessPage = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/best_selling");
  };
  return (
    <Layout>
      <MainContainer>
        <StyledImage src={orderCompleted} />
        <StyledHeading>{`Your Order has been placed`}</StyledHeading>
        <StyledButton
          onClick={handleNavigate}
        >{`Back to Product List`}</StyledButton>
      </MainContainer>
    </Layout>
  );
};

export default OrderSuccessPage;
