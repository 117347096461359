import styled from "styled-components";
import { Divider } from "antd";
import AntButton from "uiKits/Button";

export const MainContainer = styled.div`
  background: #fafafb;
  padding: 50px 35px;
`;

export const MainHeading = styled.h3`
  margin: 0px;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const BalanceWrapper = styled.div`
  margin: 50px 0px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ItemTitle = styled.h4`
  margin: 0px;
  color: #575757;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const TotalItemTitle = styled.h4`
  margin: 0px;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ItemPrice = styled.p`
  margin: 0px;
  color: #000;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const StyledDivider = styled(Divider)`
  background: #b0b0b0;
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const StyledButton = styled(AntButton)`
  height: 50px;
  width: 50%;
`;
