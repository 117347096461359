import { Carousel } from "antd";
import styled from "styled-components";

export const MainContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const StyledCarousel = styled(Carousel)`
  margin-top: 17px;
  .slick-dots li.slick-active button {
    background: #ed6928;
  }
`;

export const BannerSlide = styled.div`
  position: relative;
`;

export const SlideInnerContent = styled.div`
  position: absolute;
  top: 18%;
  width: 30%;
  left: 10%;
`;

export const BannerWrapper = styled.img`
  width: 100%;
`;

export const StyledIcon = styled.img``;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;
`;

export const StyledButton = styled.a`
  color: #fafafa;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  text-underline-offset: 6px;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
    color: #ed6928;
  }
`;

export const StyledHeading = styled.h2`
  margin: 0px;
  color: #fafafa;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 1.92px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const MainHeading = styled.span`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: capitalize;
`;

export const MainHeadingSpan = styled.span`
  margin: 0px;
  color: #ed6928;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: capitalize;
  padding-bottom: 16px;
`;
