import React from "react";
import { useNavigate } from "react-router-dom";
import BestSelling from "@/assets/Best_Selling.png";
import ThirdParty from "@/assets/Third_Party_Items.png";
import PrivateSource from "@/assets/Private_Sourcing.png";
import {
  ProductContainer,
  ImageTextWrapper,
  StyledImage,
  StyledText,
  FlexContainer,
} from "./styles";

const ProductsDropdown = () => {
  const navigate = useNavigate();
  return (
    <ProductContainer>
      <FlexContainer>
        <ImageTextWrapper onClick={() => navigate("/best_selling")}>
          <StyledImage preview={false} width={100} src={BestSelling} />
          <StyledText>Best Selling Items</StyledText>
        </ImageTextWrapper>
        <ImageTextWrapper onClick={() => navigate("/third_party_products")}>
          <StyledImage preview={false} width={100} src={ThirdParty} />
          <StyledText>Third party Items</StyledText>
        </ImageTextWrapper>
        <ImageTextWrapper>
          <StyledImage preview={false} width={100} src={PrivateSource} />
          <StyledText>Private Sourcing</StyledText>
        </ImageTextWrapper>
      </FlexContainer>
    </ProductContainer>
  );
};

export default ProductsDropdown;
