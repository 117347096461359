import styled from "styled-components";
import { Avatar, Tabs } from "antd";

export const MainContainer = styled.div``;

export const InnerContainer = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 25px;
  @media screen and (max-width: 768px) {
    width: 85%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 25px;
  }
`;

export const MainButton = styled.a`
  display: flex;
  width: 547px;
  padding: 17px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #ed6928;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;

export const MainHeading = styled.h2`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    width: 85%;
    margin: 0 auto;
  }
`;

export const AvatarWrapper = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 40px;
`;

export const StyledAvatar = styled(Avatar)`
  margin-left: -15px;
`;

export const AvatarText = styled.span`
  color: #999;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 12px;
`;

export const TabsWrapper = styled.div``;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    width: 400px;
    margin: 0 auto;
  }
  .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    background: #ed6928 !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    border-radius: 8px !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 60px !important;
    background: none;
    border: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }
  .ant-tabs-tab-btn {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-tabs-nav .ant-tabs-nav-list {
    padding: 8px;
    border-radius: 5px;
    background: #f5f5f5;
  }
  .ant-tabs-tabpane {
    width: 80%;
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    .ant-tabs-tabpane {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;
