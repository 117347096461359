import * as React from "react";

const MetaRocket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
  >
    <circle cx="18" cy="18" r="18" fill="white" />
    <path
      d="M27.6467 8.52229C27.6388 8.48382 27.62 8.44842 27.5926 8.42027C27.5652 8.39212 27.5303 8.37238 27.4921 8.36338C24.943 7.73994 19.0545 9.96135 15.861 13.1526C15.2918 13.7171 14.7728 14.33 14.3099 14.9845C13.3255 14.8954 12.3411 14.9704 11.503 15.336C9.13627 16.3767 8.44767 19.0945 8.25549 20.2612C8.24507 20.3251 8.24952 20.3906 8.26849 20.4525C8.28745 20.5145 8.32042 20.5712 8.36484 20.6183C8.40926 20.6655 8.46394 20.7018 8.52464 20.7244C8.58534 20.747 8.65043 20.7553 8.71486 20.7487L12.515 20.3315C12.5176 20.6181 12.5349 20.9044 12.5666 21.1893C12.5864 21.3862 12.6743 21.57 12.815 21.7092L14.2864 23.1806C14.4257 23.3214 14.6097 23.4093 14.8067 23.429C15.0899 23.4606 15.3745 23.4778 15.6594 23.4806L15.2427 27.2774C15.2362 27.3418 15.2445 27.4068 15.2672 27.4674C15.2898 27.528 15.326 27.5826 15.3731 27.6269C15.4202 27.6713 15.4768 27.7042 15.5386 27.7232C15.6005 27.7422 15.6659 27.7467 15.7297 27.7363C16.8969 27.5488 19.6203 26.8603 20.6549 24.494C21.0205 23.6549 21.0955 22.6757 21.0111 21.696C21.6675 21.2335 22.2822 20.7145 22.8481 20.1449C26.0535 16.9574 28.2608 11.1984 27.6467 8.52229ZM19.9991 16.0157C19.7191 15.7358 19.5284 15.3792 19.4512 14.9909C19.3739 14.6026 19.4135 14.2001 19.565 13.8344C19.7165 13.4686 19.973 13.156 20.3022 12.936C20.6313 12.7161 21.0183 12.5987 21.4142 12.5987C21.8101 12.5987 22.1971 12.7161 22.5263 12.936C22.8555 13.156 23.112 13.4686 23.2635 13.8344C23.415 14.2001 23.4546 14.6026 23.3773 14.9909C23.3 15.3792 23.1094 15.7358 22.8294 16.0157C22.6437 16.2018 22.4231 16.3495 22.1803 16.4502C21.9375 16.5509 21.6771 16.6028 21.4142 16.6028C21.1513 16.6028 20.891 16.5509 20.6482 16.4502C20.4054 16.3495 20.1848 16.2018 19.9991 16.0157Z"
      stroke="#ED6928"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.1394 22.5C10.6721 22.5574 10.2374 22.7692 9.90422 23.1019C9.07828 23.9306 9 27 9 27C9 27 12.0712 26.9217 12.8977 26.0948C13.2317 25.7622 13.4439 25.3268 13.5 24.8588"
      stroke="#ED6928"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default MetaRocket;
