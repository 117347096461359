import React from "react";
import { useNavigate } from "react-router-dom";
import gamePic from "@/assets/gamePic.png";
import Lock from "@/assets/icons/lock";
import {
  MainContainer,
  InnerContainer,
  ImageWrapper,
  StyledImage,
  TitleWrapper,
  StyledTitle,
  PriceWrapper,
  StyledPrice,
  InnerPriceWrapper,
  PriceWrapperAfterLogin,
  MsrpPriceWrapper,
  StyledPriceAfterLogin,
} from "./Styles";
import cache from "utils/cache";

const ProductCard = ({ product }) => {
  const user = cache.getItem("user");
  const navigate = useNavigate();
  return (
    <MainContainer onClick={() => navigate(`/product/${product?.id}`)}>
      <InnerContainer>
        <ImageWrapper>
          <StyledImage src={gamePic}></StyledImage>
        </ImageWrapper>
        <TitleWrapper>
          <StyledTitle>{product?.name}</StyledTitle>
        </TitleWrapper>
        <PriceWrapper>
          {user ? (
            <PriceWrapperAfterLogin>
              <StyledPriceAfterLogin>
                {"$" + product?.price}
              </StyledPriceAfterLogin>
              <MsrpPriceWrapper>
                <StyledPrice>{`MSRP `}</StyledPrice>
                <StyledPrice>{product?.MSRP}</StyledPrice>
              </MsrpPriceWrapper>
            </PriceWrapperAfterLogin>
          ) : (
            <InnerPriceWrapper>
              <StyledPrice>{`MSRP `}</StyledPrice>
              <StyledPrice>{product?.MSRP}</StyledPrice>
              <Lock />
            </InnerPriceWrapper>
          )}
        </PriceWrapper>
      </InnerContainer>
    </MainContainer>
  );
};

export default ProductCard;
