import React from "react";
import { Form, Input, InputNumber, Select, Radio } from "antd";
import {
  FieldsWrapper,
  MainContainer,
  MainSubHeading,
  StyledButton,
} from "./styles";
const { Option } = Select;

const SupplierForm = () => {
  const onFinish = (values) => {
    console.log("Form values:", values);
  };

  return (
    <MainContainer>
      <Form
        layout="vertical"
        style={{
          maxWidth: "100%",
        }}
        onFinish={onFinish}
      >
        <FieldsWrapper>
          <Form.Item
            label="First Name"
            name="First_name"
            rules={[
              {
                required: true,
                message: "Please write your first name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="Last_name"
            rules={[
              {
                required: true,
                message: "Please write your last name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </FieldsWrapper>
        <FieldsWrapper>
          <Form.Item
            label="Mobile Phone Number"
            name="MobileNumber"
            rules={[
              {
                required: true,
                message: "Please write your mobile number!",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Business Email"
            name="BusinessEmail"
            rules={[
              {
                required: true,
                message: "Please write your business email!",
                type: "email",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </FieldsWrapper>
        <FieldsWrapper>
          <Form.Item
            label="What is your company website?"
            name="CompanyWebsite"
            rules={[
              {
                required: true,
                message: "Please write your company website!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Which city and state do you ship from?"
            name="stateFromShip"
            rules={[
              {
                required: true,
                message: "Please write your city and state!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </FieldsWrapper>
        <FieldsWrapper>
          <Form.Item
            label="What product and category do you want to supply to ECOMDROPSHIP? (This form is for suppliers.)"
            name="category"
            rules={[
              {
                required: true,
                message: "Please select a category.",
              },
            ]}
          >
            <Select
              placeholder="Select a category"
              style={{
                width: "100%",
              }}
            >
              <Option value="electronics">Electronics</Option>
              <Option value="fashion">Fashion</Option>
              <Option value="home_goods">Home Goods</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Are you a brand or a wholesale business? Do you carry inventory in the US that can be supplied for ECOMDROPSHIP users?"
            name="inventoryCarryInUS"
            rules={[
              {
                required: true,
                message: "Please write here!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </FieldsWrapper>
        <FieldsWrapper>
          <Form.Item
            label="What's your current annual sales volume?"
            name="AnnualSalesVolume"
            rules={[
              {
                required: true,
                message: "Please write here!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </FieldsWrapper>
        <FieldsWrapper>
          <Form.Item
            label="Can you do consignment in our Seattle warehouse? (Send stock to us)*"
            name="Send_stock_to_us"
          >
            <Radio.Group>
              <Radio value="Yes"> Yes </Radio>
              <Radio value="No"> No </Radio>
            </Radio.Group>
          </Form.Item>
        </FieldsWrapper>

        <FieldsWrapper>
          <Form.Item
            label="How fast can you ship out product once receiving an order?*"
            name="Shipping_time"
            rules={[
              {
                required: true,
                message: "Please select a category.",
              },
            ]}
          >
            <Select
              placeholder="Select an Option"
              style={{
                width: "100%",
              }}
            >
              <Option value="1-3_Days">1-3 Days</Option>
              <Option value="4-6_Days">4-6 Days</Option>
              <Option value="7-10_Days">7-10 Days</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="What other marketplace(s) do you currently work with?*"
            name="marketplace"
            rules={[
              {
                required: true,
                message: "Please write here!",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </FieldsWrapper>

        <Form.Item
          label="What else would you like to tell us?"
          name="TextArea"
          rules={[
            {
              required: true,
              message: "Please provide additional information!",
            },
          ]}
        >
          <Input.TextArea size="large" />
        </Form.Item>
        <MainSubHeading>
          {`Thanks,`}
          <br />
          {`TEAM ECOMDROPSHIP`}
          {` - "Your One-stop platform for your Beauty Business"`}
        </MainSubHeading>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <StyledButton type="primary" htmlType="submit">
            Submit
          </StyledButton>
        </Form.Item>
      </Form>
    </MainContainer>
  );
};

export default SupplierForm;
