import cache from "utils/cache";

const AuthService = {
  isAuthenticated: () => {
    const user = cache.getItem("user");
    return user;
  },
  getUser: () => {
    return cache.getItem("user");
  },
};

export default AuthService;
