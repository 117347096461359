import { Button } from "antd";
import styled from "styled-components";

export const AntButton = styled(Button)`
  color: #fff;
  background: #ed6928;
  border-color: #ed6928;
  &:hover {
    color: #ed6928 !important;
    border-color: #ed6928 !important;
  }
`;
