import React, { useState } from "react";
import {
  InnerContainer,
  LeftCol,
  MainContainer,
  RightCol,
  StyledMenu,
} from "./styles";
import ProfileForm from "../ProfileForm";
import ProfileIcon from "@/assets/icons/profile";
import ShippingAddresses from "@/assets/icons/shippingaddress";
import ShippingForm from "../ShippingForm";
import BillingInformationIcon from "@/assets/icons/billingInformationIcon";
import SubscriptionBilling from "../SubscriptionBilling";

const Profile = () => {
  const [current, setCurrent] = useState("profile");

  const items = [
    {
      label: "Profile",
      key: "profile",
      icon: <ProfileIcon selected={current === "profile"} />,
      component: <ProfileForm />,
    },
    {
      label: "Shipping Addresses",
      key: "shippingAddresses",
      icon: <ShippingAddresses selected={current === "shippingAddresses"} />,
      component: <ShippingForm />,
    },
    {
      label: "Billing Information",
      key: "billingInformation",
      icon: (
        <BillingInformationIcon selected={current === "billingInformation"} />
      ),
      component: <SubscriptionBilling />,
    },
  ];

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const selectedItem = items.find((item) => item.key === current);

  return (
    <MainContainer>
      <InnerContainer>
        <LeftCol>
          <StyledMenu
            onClick={onClick}
            style={{
              width: 300,
            }}
            defaultSelectedKeys={["profile"]}
            mode="inline"
            items={items}
          />
        </LeftCol>
        <RightCol>{selectedItem && selectedItem.component}</RightCol>
      </InnerContainer>
    </MainContainer>
  );
};

export default Profile;
