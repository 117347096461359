import { Divider, message, Upload } from "antd";
import React, { useState } from "react";
import {
  InnerContainer,
  MainContainer,
  MainHeading,
  StyledButton,
  StyledRow,
  StyledTitle,
  StyledInput,
  StyledPhoneRow,
  StyledImageUploadWrapper,
  StyledInnerWrapper,
  StyledSubHeading,
  StyledSubHeading2,
  StyledUploadButton,
} from "./styles";
import cache from "utils/cache";

const ProfileForm = () => {
  const [editingFirstName, setEditingFirstName] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false); // New state for phone

  const user = cache.getItem("user");
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phoneNumber); // New state for phone number

  const [inputValueFirstName, setInputValueFirstName] = useState("");
  const [inputValueLastName, setInputValueLastName] = useState("");
  const [inputValueEmail, setInputValueEmail] = useState("");
  const [inputValuePhone, setInputValuePhone] = useState(""); // New state for input phone number

  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleUpdate = (fieldName) => {
    switch (fieldName) {
      case "firstName":
        setInputValueFirstName(firstName);
        setEditingFirstName(true);
        break;
      case "lastName":
        setInputValueLastName(lastName);
        setEditingLastName(true);
        break;
      case "email":
        setInputValueEmail(email);
        setEditingEmail(true);
        break;
      case "phone":
        setInputValuePhone(phone);
        setEditingPhone(true);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        setInputValueFirstName(value);
        break;
      case "lastName":
        setInputValueLastName(value);
        break;
      case "email":
        setInputValueEmail(value);
        break;
      case "phone":
        setInputValuePhone(value);
        break;
      default:
        break;
    }
  };

  const handleSave = (fieldName) => {
    switch (fieldName) {
      case "firstName":
        setFirstName(inputValueFirstName);
        setEditingFirstName(false);
        break;
      case "lastName":
        setLastName(inputValueLastName);
        setEditingLastName(false);
        break;
      case "email":
        setEmail(inputValueEmail);
        setEditingEmail(false);
        break;
      case "phone":
        setPhone(inputValuePhone);
        setEditingPhone(false);
        break;
      default:
        break;
    }
  };

  return (
    <MainContainer>
      <InnerContainer>
        <MainHeading>{`Profile`}</MainHeading>

        {/* First Name Field */}
        <StyledRow>
          <StyledTitle>First Name</StyledTitle>
          {editingFirstName ? (
            <StyledInput
              type="text"
              name="firstName"
              value={inputValueFirstName}
              onChange={handleInputChange}
            />
          ) : (
            <StyledTitle>{firstName}</StyledTitle>
          )}
          <StyledButton
            onClick={() =>
              editingFirstName
                ? handleSave("firstName")
                : handleUpdate("firstName")
            }
          >
            {editingFirstName ? "Save" : "Update"}
          </StyledButton>
        </StyledRow>

        {/* Last Name Field */}
        <StyledRow>
          <StyledTitle>Last Name</StyledTitle>
          {editingLastName ? (
            <StyledInput
              type="text"
              name="lastName"
              value={inputValueLastName}
              onChange={handleInputChange}
            />
          ) : (
            <StyledTitle>{lastName}</StyledTitle>
          )}
          <StyledButton
            onClick={() =>
              editingLastName
                ? handleSave("lastName")
                : handleUpdate("lastName")
            }
          >
            {editingLastName ? "Save" : "Update"}
          </StyledButton>
        </StyledRow>

        {/* Email Field */}
        <StyledRow>
          <StyledTitle>Email Address</StyledTitle>
          {editingEmail ? (
            <StyledInput
              type="text"
              name="email"
              value={inputValueEmail}
              onChange={handleInputChange}
            />
          ) : (
            <StyledTitle>{email}</StyledTitle>
          )}
          <StyledButton
            onClick={() =>
              editingEmail ? handleSave("email") : handleUpdate("email")
            }
          >
            {editingEmail ? "Save" : "Update"}
          </StyledButton>
        </StyledRow>

        {/* Phone Number Field */}
        <StyledPhoneRow>
          <StyledTitle>Phone Number</StyledTitle>
          {editingPhone ? (
            <StyledInput
              type="text"
              name="phone"
              value={inputValuePhone}
              onChange={handleInputChange}
            />
          ) : (
            <StyledTitle>{phone}</StyledTitle>
          )}
          <StyledButton
            onClick={() =>
              editingPhone ? handleSave("phone") : handleUpdate("phone")
            }
          >
            {editingPhone ? "Save" : "Update"}
          </StyledButton>
        </StyledPhoneRow>
      </InnerContainer>
    </MainContainer>
  );
};

export default ProfileForm;
