import * as React from "react";

const MetaComment = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
  >
    <circle cx="18" cy="18" r="18" fill="white" />
    <path
      d="M8.25 10.5V25.5H15.4222L17.4608 27.54L18.0007 28.0545L18.5408 27.5392L20.5778 25.5H27.75V10.5H8.25ZM9.75 12H26.25V24H19.9455L19.7108 24.21L18 25.9222L16.29 24.2123L16.0545 24.0007H9.75V12ZM12.75 14.25V15.75H23.25V14.25H12.75ZM12.75 17.25V18.75H23.25V17.25H12.75ZM12.75 20.25V21.75H20.25V20.25H12.75Z"
      fill="#ED6928"
    />
  </svg>
);

export default MetaComment;
