import { Divider } from "antd";
import React, { useState } from "react";
import "react-phone-number-input/style.css";

import {
  InnerContainer,
  MainContainer,
  MainHeading,
  StyledPhone,
  StyledRow,
  StyledTitle,
  StyledInput,
  StyledCountry,
} from "./styles";

const options = [
  {
    value: "jack",
    label: "Jack",
  },
  {
    value: "lucy",
    label: "Lucy",
  },
  {
    value: "Yiminghe",
    label: "yiminghe",
  },
  {
    value: "disabled",
    label: "Disabled",
    disabled: true,
  },
];

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const ShippingForm = () => {
  const [phone, setPhone] = useState();
  const [country, setCountry] = useState();
  return (
    <MainContainer>
      <InnerContainer>
        <MainHeading>{`shipping address`}</MainHeading>
        <Divider />
        <StyledRow>
          <StyledTitle>{`First Name`}</StyledTitle>
          <StyledInput />
        </StyledRow>
        <StyledRow>
          <StyledTitle>{`Last Name`}</StyledTitle>
          <StyledInput />
        </StyledRow>
        <StyledRow>
          <StyledTitle>{`Country Or Region`}</StyledTitle>
          <StyledCountry
            value={country}
            valueType="short"
            onChange={(val) => setCountry(val)}
          />
        </StyledRow>
        <StyledRow>
          <StyledTitle>{`Address Line 1`}</StyledTitle>
          <StyledInput placeholder="street address" />
        </StyledRow>
        <StyledRow>
          <StyledTitle>{`Address Line 2`}</StyledTitle>
          <StyledInput placeholder="street address" />
        </StyledRow>
        <StyledRow>
          <StyledTitle>{`Phone Number`}</StyledTitle>
          <StyledPhone
            defaultCountry={country}
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
          />
        </StyledRow>
      </InnerContainer>
    </MainContainer>
  );
};

export default ShippingForm;
