import styled from "styled-components";
import AntBreadCrumbs from "uiKits/BreadCrums";

export const StyledBreadCrumbs = styled(AntBreadCrumbs)``;

export const HeroSection = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-bottom: 90px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
`;
