import React from "react";
import AnnouncementBar from "../MarketingPage/AnnouncementBar";
import Header from "../MarketingPage/Header";
import Profile from "./Profile";

const ProfilePage = () => {
  return (
    <>
      <AnnouncementBar />
      <Header />
      <Profile />
    </>
  );
};
export default ProfilePage;
