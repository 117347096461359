import styled from "styled-components";
import AntButton from "uiKits/Button";

export const MainContainer = styled.div`
  text-align: center;
`;

export const StyledImage = styled.img``;

export const StyledHeading = styled.h2`
  color: var(--Text2, #000);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const StyledButton = styled(AntButton)`
  height: 50px;
  width: 500px;
`;
