import React, { useState, useEffect } from "react";
import Layout from "layout";
import { useNavigate } from "react-router-dom";
import {
  ButtonWrapper,
  CartFooter,
  EmptyCartImage,
  EmptyCartText,
  EmptyCartWrapper,
  EstimatedTimeWithIcon,
  InnerContainer,
  MainContainer,
  StyledBreadCrumbs,
  StyledButton,
  StyledHeading,
  StyledTotal,
  TimeText,
  TotalWrapper,
} from "./styles";
import ProductLineItems from "./ProductLineItems";
import ClockIcon from "@/assets/icons/clockIcon";
import Folder from "@/assets/Folder.png";
import cache from "@/utils/cache";
import moment from "moment"; // Import moment for date handling

const CartPage = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const cachedItems = cache.getItem("cartItems") || [];
    setCartItems(cachedItems);
  }, []);

  const handleNavigate = () => {
    navigate("/checkout");
  };

  const handleRemoveItem = (id) => {
    const updatedItems = cartItems.filter((item) => item.productId !== id);
    setCartItems(updatedItems);
    cache.setItem("cartItems", updatedItems);
  };

  const handleQuantityChange = (id, newQuantity) => {
    const updatedItems = cartItems.map((item) =>
      item.productId === id ? { ...item, productQuantity: newQuantity } : item
    );
    setCartItems(updatedItems);
    cache.setItem("cartItems", updatedItems);
  };

  const calculateTotalPrice = () => {
    return cartItems
      .reduce((total, item) => {
        const itemPrice =
          item.variantPrice !== 0 ? item.variantPrice : item.productPrice;
        return total + itemPrice * item.productQuantity;
      }, 0)
      .toFixed(2); // Ensure the total is always formatted to 2 decimal places
  };

  const getEstimatedArrival = () => {
    const startDate = moment().add(1, "days").format("MMM DD");
    const endDate = moment().add(5, "days").format("MMM DD");
    return `${startDate} - ${endDate}`;
  };

  const items = [
    {
      title: <a href="">Product</a>,
    },
    {
      title: <a href="">Products Detail</a>,
    },
    {
      title: <a href="">Cart</a>,
    },
  ];

  return (
    <Layout>
      <StyledBreadCrumbs items={items} />
      <MainContainer>
        <StyledHeading>{`Shopping Cart`}</StyledHeading>
        {!cartItems.length ? (
          <EmptyCartWrapper>
            <EmptyCartImage src={Folder} />
            <EmptyCartText>{`No Item added`}</EmptyCartText>
          </EmptyCartWrapper>
        ) : (
          <>
            <InnerContainer>
              {cartItems.map((cartItem) => (
                <ProductLineItems
                  key={cartItem.productId}
                  cartItem={cartItem}
                  onRemove={handleRemoveItem}
                  onQuantityChange={handleQuantityChange}
                />
              ))}
            </InnerContainer>
            <CartFooter>
              <EstimatedTimeWithIcon>
                <ClockIcon />
                <TimeText>{`Estimated Arrival: ${getEstimatedArrival()}`}</TimeText>
                <TotalWrapper>
                  <StyledTotal>{`Total:  $${calculateTotalPrice()}`}</StyledTotal>
                </TotalWrapper>
              </EstimatedTimeWithIcon>
              <ButtonWrapper>
                <StyledButton onClick={handleNavigate}>
                  {`Continue to Checkout`}
                </StyledButton>
              </ButtonWrapper>
            </CartFooter>
          </>
        )}
      </MainContainer>
    </Layout>
  );
};

export default CartPage;
