import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  background-color: #fdf3e4;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    background-color: #fff;
  }
`;

export const InnerContainer = styled.div`
  width: 90%;
  padding-top: 50px;
  align-items: start;
  margin: 0 auto;
  padding-bottom: 100px;
`;

export const StyledRow = styled.div`
  display: flex;
  margin-top: 100px;
  gap: 5%;
  align-items: start;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const StyledRowmb = styled.div`
  display: flex;
  margin-top: 100px;
  gap: 5%;
  align-items: start;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 30px;
  }
`;

export const RightCol = styled.div`
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const LeftCol = styled.div`
  width: 55%;
  .highlighted {
    border-radius: 5px;
    border-left: 9px solid rgba(237, 105, 40, 0.92);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .highlighted {
      width: 70%;
      margin: 0 auto;
    }
  }
`;

export const StyledSpacer = styled.div`
  height: 100px;
`;

export const TextCard = styled.div`
  width: 70%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
`;

export const SimpleTextCard = styled.div`
  width: 70%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
`;

export const HeadingsWrapper = styled.div``;

export const StyledMainHeading = styled.h2`
  margin: 0px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const StyledSubHeading = styled.h5`
  margin: 0px;
  color: #585858;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StyledHeading = styled.h4`
  margin: 0px;
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  padding: 16px 0px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const StyledText = styled.p`
  margin: 0px;
  color: #585858;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const StyledSpan = styled.span`
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
  color: #ed6928;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
`;
