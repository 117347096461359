import React, { useState } from "react";
import { columns } from "./columns";
import {
  InnerContainer,
  MainContainer,
  MainHeading,
  MainSubHeading,
  StyledTable,
  StyledTabs,
  TableWrapper,
} from "./styles";
// import ExpandedRowTableContent from "../ExpandedRowTableContent";
import { useGetOrderQuery } from "api/orderApi";

const OrdersDetail = () => {
  // const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState("supplier"); // Track active tab
  const { data: ordersList } = useGetOrderQuery(activeTab === "supplier");

  console.log("ordersList", ordersList);

  const onChange = (key) => {
    setActiveTab(key); // Update active tab
  };

  const items = [
    {
      key: "supplier",
      label: "Supplier",
      children: (
        <TableWrapper>
          <StyledTable
            columns={columns}
            dataSource={ordersList?.results}
            pagination={true}
            // expandable={{
            //   expandedRowRender: (record) => {
            //     return <ExpandedRowTableContent heading="Notes" />;
            //   },
            //   expandRowByClick: false,
            //   expandIcon: ({ expanded, onExpand, record }) => {
            //     return expanded ? (
            //       <DownOutlined onClick={(e) => onExpand(record, e)} />
            //     ) : (
            //       <RightOutlined onClick={(e) => onExpand(record, e)} />
            //     );
            //   },
            //   expandedRowKeys: expanded,
            //   onExpand: (expanded, record) => {
            //     const keys = [];
            //     if (expanded) {
            //       keys.push(record.key);
            //     }
            //     setExpanded(keys);
            //   },
            // }}
          />
        </TableWrapper>
      ),
    },
    {
      key: "drop_shipper",
      label: "Drop shipper",
      children: (
        <TableWrapper>
          <StyledTable
            columns={columns}
            dataSource={ordersList?.results}
            pagination={true}
            // expandable={{
            //   expandedRowRender: (record) => {
            //     return <ExpandedRowTableContent heading="Notes" />;
            //   },
            //   expandRowByClick: false,
            //   expandIcon: ({ expanded, onExpand, record }) => {
            //     return expanded ? (
            //       <DownOutlined onClick={(e) => onExpand(record, e)} />
            //     ) : (
            //       <RightOutlined onClick={(e) => onExpand(record, e)} />
            //     );
            //   },
            //   expandedRowKeys: expanded,
            //   onExpand: (expanded, record) => {
            //     const keys = [];
            //     if (expanded) {
            //       keys.push(record.key);
            //     }
            //     setExpanded(keys);
            //   },
            // }}
          />
        </TableWrapper>
      ),
    },
  ];

  return (
    <MainContainer>
      <MainHeading>{`My Orders`}</MainHeading>
      <MainSubHeading>{`Check the status of recent orders, and manage orders here.`}</MainSubHeading>
      <InnerContainer>
        <StyledTabs
          defaultActiveKey="supplier"
          items={items}
          onChange={onChange}
        />
      </InnerContainer>
    </MainContainer>
  );
};

export default OrdersDetail;
